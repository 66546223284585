import {Box, Typography} from "@mui/material";
import React from "react";

export default function AddressCity({address}) {
    return (
        <Box component={'span'}>
            <Typography display='inline' sx={{fontWeight: "bold", paddingRight: "0.5em"}} variant="body2">City:</Typography>
            {address.city &&
                <Typography display='inline' variant="body2">
                    {address.city}
                </Typography>
            }
            {address.state &&
                <Typography display='inline' variant="body2">
                    {", " + address.state}
                </Typography>
            }
            {address.country &&
                <Typography display='inline' variant="body2" style={{marginLeft: '0.5em'}}>
                    {"(" + address.country + ")"}
                </Typography>
            }
        </Box>
    );
}