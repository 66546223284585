import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import {useAuth} from "react-oidc-context";
import config from "../../config";

interface Props {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    window?: () => Window;
}

const drawerWidth = 240;
const title = "VACOMBO";
export default function Header(props: Props) {
    const auth = useAuth();
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleLogout = () => {
        auth.signoutRedirect({id_token_hint: auth.user.id_token,
            post_logout_redirect_uri: config.post_logout_redirect_uri} );
    };

    const handleLogin = () => {
        auth.signinRedirect();

    }

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const authDrawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                {title}
            </Typography>
            <Divider />
            <List>
                <ListItem component="a" href='/'>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem component="a" href='/account'>
                    <ListItemText primary="Account" />
                </ListItem>
                <ListItem component="a" onClick={handleLogout} href='#'>
                    <ListItemText primary="Logout" />
                </ListItem>
            </List>
        </Box>
    );

    const unauthDrawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                {title}
            </Typography>
            <Divider />
            <List>
                <ListItem component="a" href='/'>
                    <ListItemText primary="Home" />
                </ListItem>
                <ListItem onClick={handleLogin}>
                    <ListItemText primary="Login" />
                </ListItem>
                <ListItem component="a" href='/register'>
                    <ListItemText primary="Register" />
                </ListItem>
                <ListItem component="a" href='/about'>
                    <ListItemText primary="About" />
                </ListItem>
            </List>
        </Box>
    );

    const authBox = (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Link href='/' sx={{ mr: 2, color: '#fff' }}>
            Home
        </Link>
        <Link href='/account' sx={{ mr: 2, color: '#fff' }}>
            Account
        </Link>
        <Link onClick={handleLogout} sx={{ mr: 2, color: '#fff' }}>
            Logout
        </Link>
    </Box>
    );

    const unauthBox = (
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Link href='/' sx={{ mr: 2, color: '#fff' }}>
                Home
            </Link>
            <Link onClick={handleLogin} sx={{ mr: 2, color: '#fff' }}>
                Login
            </Link>
            <Link href='/register' sx={{ mr: 2, color: '#fff' }}>
                Register
            </Link>
            <Link href='/about' sx={{ mr: 2, color: '#fff' }}>
                About
            </Link>
        </Box>
    );

    let drawer = unauthDrawer;
    let box = unauthBox;

    if (auth.isAuthenticated) {
        drawer = authDrawer;
        box = authBox;
    }
    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar component="nav">
                <Toolbar>
                    <Box component="img" sx={{width: '50px', height:'50px', mt: '10px', mb: '10px', mr: '10px'}} src="/logo-white.png" />
                    <Typography
                        variant="h6"
                        component="div"
                        sx={{ flexGrow: 1, display: { xs: 'block', sm: 'block' } }}
                    >
                        {title}
                    </Typography>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {box}
                </Toolbar>
            </AppBar>
            <Box component="nav">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </Box>
    );
}