import {
    Grid, Box, Typography,
} from '@mui/material';

const About = () => {

    return(
        <Box>
            <Typography variant={"h6"}>About Vacombo</Typography>
            <Grid container direction="row">
                <Grid item xs={12} sm={8}>
                    <Typography variant={"body1"} paragraph={true} sx={{margin: "10px"}}>
                        Our mission is to provide property owners and managers a best in class <i>by owner</i> property management experience,
                        no long term commitments, straight-forward flexible pricing and no hidden fees for owners or customers.
                    </Typography>
                    <Typography variant={"body1"} paragraph={true} sx={{margin: "10px"}}>
                        Add your property to existing vacation rental communities or manage your own communities.  Advertise anywhere with our easy
                        to use links that are designed to be embedded into 3rd party websites, including your own.  Our property management features
                        are expanding to include all of the features you would expect from a property management website but without the high fees and
                        restrictions that other platforms impose on you and your customers.
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Box sx={{width: "200px", height: "200px"}}>
                        <img src="/logo-black.png" alt={"vacombo logo"} />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default About;
