import React, { useState, useEffect, Fragment } from 'react';
import ReactGA from "react-ga4";
import {Navigate, useParams} from 'react-router-dom';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Link,
    useMediaQuery,
    Button,
    Menu,
    MenuItem,
    Dialog,
    DialogContent,
    Box,
    IconButton,
    Collapse,
} from '@mui/material';
import PropertyDetail from './PropertyDetail';
import PropertyForm from './PropertyForm';
import {ApiClient} from "../common/AxiosClient";
import {useAuth} from "react-oidc-context";
import config from "../../config";
import {theme} from "../../index";
import {KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import CommunityProperties from "../community/CommunityProperties";

const ManageProperty = () => {
    const { uid} = useParams();
    const auth = useAuth();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const [ property, setProperty] = useState({});
    const [ communities, setCommunities] = useState({});
    const [ openCommunities, setOpenCommunities] = useState(true);
    const [ openPendingCommunities, setOpenPendingCommunities] = useState(false);
    const [ openNearbyCommunities, setOpenNearbyCommunities] = useState(false);
    const [ state, setState] = useState({
        uid: uid,
        needsRefresh: true,
        isLoading: false,
        remove: false,
        isRemoving: false,
        isEditing: uid === "add",
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.uid !== "add" && state.needsRefresh && !state.isLoading && !state.errorMessage && !state.isEditing) {
            const token = auth.user.access_token;
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/owner/properties/" + state.uid,{
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setProperty(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
        }
        if (state.remove && !state.isLoading) {
            setState(prevState => ({...prevState, isLoading: true})); // avoid removing it twice
            const token = auth.user.access_token;
            ApiClient.delete("/owner/properties/" + uid,{
                headers: {
                    Authorization: `Bearer ${token}`,
                }})
                .then((response) => {
                    setState(prevState => ({...prevState, remove: false, isLoading: false, redirect: "/account" }));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state]);

    useEffect(() => {
        if (property && state.uid !== 'add' && !state.isLoading && !state.errorMessage) {
            const token = auth.user.access_token;
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/owner/properties/" + uid + "/communities",{
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setCommunities(response.data);
                    setState(prevState => ({...prevState, isLoading: false, propsLoaded: true }));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [property]);

    useEffect(() => {
        if (property.address?.latitude && property.address?.longitude && communities?.nearby) {
            communities.nearby.forEach((comm) => {
                comm.distance = Math.round(haversineMiles(property.address.latitude,property.address.longitude,comm.community.address.latitude,comm.community.address.longitude) * 100)/100;
            })
        }
    }, [communities]);

    const haversineMiles = (lat1,lon1,lat2,lon2) => {
        var dLat = (lat2-lat1) * (Math.PI/180);
        var dLon = (lon2-lon1) * (Math.PI/180);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * (Math.PI/180)) * Math.cos(lat2 * (Math.PI/180)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        return 3960 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    }

    const handleEditProperty = (popupState) => {
        ReactGA.event({
            category: "property",
            action: "edit",
            value: uid,
        });
        setState(prevState => ({...prevState, isEditing: true}));
        popupState.close();
    }

    const handleRemoveProperty = (popupState) => {
        setState(prevState => ({...prevState, isRemoving: true}));
        popupState.close();
    }

    const handleAction = (action, cuid) => {
        if (action === "request") {
            setState(prevState => ({...prevState, redirect: "/account/properties/" + state.uid + "/request?cuid=" + cuid }));
            return;
        }
        ReactGA.event({
            category: "property",
            action: action,
            label: cuid,
        });
        const token = auth.user.access_token;
        let data = {
            action: action,
            cuid: cuid
        };
        setState(prevState => ({...prevState, isLoading: true}));
        ApiClient.post("/owner/properties/" + state.uid + "/communities", JSON.stringify(data), {
            headers: {
                Authorization: `Bearer ${token}`,
                'Content-Type': `application/json`,
            }})
            .then(response=> {
                setState(prevState => ({...prevState, isLoading: false, needsRefresh: true}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
            });
    }

    if (state.redirect) {
        return <Navigate to={state.redirect} />;
    }

    const breadcrumbs = (
        <Grid container direction="row">
            <Grid item>
                <Breadcrumbs color={'primary'}>
                    <Link underline="hover" color="inherit" href="/account">
                        Account
                    </Link>
                    <Typography>
                        {property.name ? property.name + " " : "New "}
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );

    if (state.isEditing) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                {breadcrumbs}
                <PropertyForm onClose={(uid) => {
                    setState(prevState => ({...prevState, uid: uid, isEditing: false, needsRefresh: true}));
                    window.history.replaceState(null, null, "/account/properties/" + uid);
                }} propData={property}/>
            </GoogleReCaptchaProvider>
        );
    } else if (state.viewCommunities) {
        return (
            <Fragment>
                {breadcrumbs}
                <PropertyForm onClose={(uid) => {setState(prevState => ({...prevState, uid: uid, isEditing: false, needsRefresh: true})); window.history.replaceState(null,null,"/account/properties/" + uid);}} propData={property}/>
            </Fragment>
        );
    } else {
        return (
            <Fragment>
                {breadcrumbs}
                {property &&
                    <Fragment>
                    <Box>
                        {property && property.warning && <Box sx={{backgroundColor: 'warning.main', textAlign: 'center'}}>{property.warning}</Box>}
                        <Box display={"flex"} justifyContent={"right"}>
                            <PopupState variant="popover" popupId="demo-popup-menu">
                                {(popupState) => (
                                    <Fragment>
                                        <Button variant="contained" {...bindTrigger(popupState)}>
                                            Actions
                                        </Button>
                                        <Menu {...bindMenu(popupState)}>
                                            <MenuItem onClick={() => {handleEditProperty(popupState)}}>Edit</MenuItem>
                                            <MenuItem onClick={() => {handleRemoveProperty(popupState)}}>Delete</MenuItem>
                                            <MenuItem onClick={() => {setState(prevState => ({...prevState, viewCommunities: true}))}}>Nearby Communities</MenuItem>
                                        </Menu>
                                    </Fragment>
                                )}
                            </PopupState>
                        </Box>
                    </Box>
                    <PropertyDetail property={property} mediaPerPage={mediaPerPage} />
        <Grid container spacing={2} justifyContent={"center"}>
            <Grid item xs={12} md={8} alignContent={"center"}>
                <Typography variant={'h6'}>Communities</Typography>
                <Typography variant={'h7'}>
                    <IconButton
                        onClick={() => setOpenCommunities(!openCommunities)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openCommunities? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Approved ({communities?.approved?.length ? communities.approved.length : 0})
                </Typography>
                <Collapse in={openCommunities}>
                    <Box>
                        <Box sx={{pl: '1em', mb: '1em'}}>
                            <CommunityProperties properties={communities.approved} action={handleAction}/>
                        </Box>
                    </Box>
                </Collapse>
                <Typography variant={'h7'}>
                    <IconButton
                        onClick={() => setOpenPendingCommunities(!openPendingCommunities)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openPendingCommunities ? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Pending ({communities.pending?.length ? communities.pending.length: 0})
                </Typography>
                <Collapse in={openPendingCommunities}>
                    <Box>
                        <Box sx={{pl: '1em', mb: '1em'}}>
                            <CommunityProperties properties={communities.pending} action={handleAction}/>
                        </Box>
                    </Box>
                </Collapse>
                <Typography variant={'h7'}>
                    <IconButton
                        onClick={() => setOpenNearbyCommunities(!openNearbyCommunities)}
                        aria-label="expand"
                        size="small"
                        color={'primary'}
                    >
                        {openNearbyCommunities? <KeyboardArrowDown />
                            : <KeyboardArrowRight />}
                    </IconButton>
                    Other Nearby Communities ({communities.nearby?.length ? communities.nearby.length: 0})
                </Typography>
                <Collapse in={openNearbyCommunities}>
                    <Box>
                        <Box sx={{pl: '1em', mb: '1em'}}>
                            <CommunityProperties action={handleAction} properties={communities.nearby} />
                        </Box>
                    </Box>
                </Collapse>
            </Grid>
        </Grid>
                        </Fragment>
                }

                <Dialog open={state.isRemoving} onClose={() => this.setState(prevState => ({...prevState, isRemoving: false}))}>
                    <DialogContent>
                        <Typography>Are you sure you want to remove {property.name}? </Typography>
                        <Button autoFocus onClick={() => {setState((prevState) => ({...prevState,isRemoving: false}))}}>Cancel</Button>
                        <Button onClick={() => {
                            ReactGA.event({
                                category: 'property',
                                action: 'remove',
                                value: uid
                            });
                            setState((prevState) => ({...prevState, remove: true, isRemoving: false}))}}>Ok</Button>
                    </DialogContent>
                </Dialog>
                <Dialog open={state.errorMessage ? true : false}>
                    <DialogContent>
                        <Typography>Error: {state.errorMessage} </Typography>
                        <Button autoFocus onClick={() => {setState((prevState) => ({...prevState, errorMessage: null}))}}>Close</Button>
                    </DialogContent>
                </Dialog>
            </Fragment>);
    }
};

export default ManageProperty;