import {Grid, Box, Typography, Button, } from '@mui/material';
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAuth} from "react-oidc-context";
import {ApiClient} from "./common/AxiosClient";
import VacomboMap from "./VacomboMap";
import CommunityList from "./community/CommunityList";
import CitySearch from "./search/CitySearch";

const HomePage = () => {
    const defaultFocus = {
        zoom: 3.0,
        center: [-95.62,40.13]
    }
    const navigate = useNavigate();
    const auth = useAuth();
    const [communities, setCommunities]= useState([]);
    const [focus, setFocus]= useState(defaultFocus);
    const [state, setState] = useState({needsRefresh: true, latest: true, query: undefined});

    useEffect(() => {
        if (state.needsRefresh && !state.isLoading) {
            let url = '/communities';
            if (state.query && state.query.length > 5) {
                url += '?q=' + state.query;
            }
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get(url,{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false }));
                    setCommunities(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state]);

    const view = (geo) => {
        console.log("map " + JSON.stringify(geo));
        if (geo.area) {
            let zoom = 3;
            if (geo.area < 100) {
                zoom = 10;
            } else if (geo.area < 1000) {
                zoom = 9;
            } else if (geo.area < 10000) {
                zoom = 8;
            } else if (geo.area < 50000) {
                zoom = 7;
            } else if (geo.area < 75000) {
                zoom = 6;
            } else if (geo.area < 100000) {
                zoom = 5;
            } else if (geo.area < 250000) {
                zoom = 4;
            }
            if (geo.longitude) {
                setFocus({zoom: zoom, center: [geo.longitude, geo.latitude]});
            } else {
                setFocus({zoom: zoom, center: defaultFocus.center});
            }
        } else {
            if (!geo.longitude) {
                setFocus({zoom: defaultFocus.zoom, center: defaultFocus.center});
            } else if (geo.population > 250000) {
                setFocus({zoom: 10, center: [geo.longitude, geo.latitude]});
            } else if (geo.population > 100000) {
                setFocus({zoom: 11, center: [geo.longitude, geo.latitude]});
            } else if (geo.population > 50000) {
                setFocus({zoom: 12, center: [geo.longitude, geo.latitude]});
            } else if (geo.population > 10000) {
                setFocus({zoom: 13, center: [geo.longitude, geo.latitude]});
            } else {
                setFocus({zoom: 14, center: [geo.longitude, geo.latitude]});
            }
        }
    };

    return(
        <Box>
        <Box alignItems={"center"} justifyContent={"center"} textAlign={"center"} sx={{mb: '1em'}}>
            <Typography variant={"h3"}>Vacation Communities</Typography>
            <Typography variant={"h5"}>by Owner</Typography>
        </Box>
        <Grid container alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
            <Grid item xs={12} md={6}>
                <Grid container direction="column">
                    <Grid item justifyContent={"center"} textAlign={"center"} sx={{border: '1px solid'}}>
                        <VacomboMap initFocus={focus} />
                    </Grid>
                    <Grid item alignItems={"center"} justifyContent={"center"} textAlign={"center"}>
                        <Grid item sx={{width: "100%", padding: "10px"}}>
                            <CitySearch onChange={view}/>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} md={6}>
                <Box justifyContent={"center"} textAlign={"center"} sx={{width: "350px"}}>
                    <CommunityList communities={communities} imgWidth={'180px'} imgHeight={'120px'} />
                    <Typography variant={"error"}>{state.errorMessage}</Typography>
                </Box>
            </Grid>
        </Grid>
            {!auth.isAuthenticated && (
                <Box>
                    <Typography variant={"h6"}>Property Owners and Managers</Typography>
                    <Typography variant={"body1"} sx={{margin: "10px"}}>
                        <Button onClick={() => {navigate('/account')}}>Login or Register</Button> and begin enjoying a better property management experience.
                        There is no purchase necessary and no credit card required to start marketing your communities and properties today.
                    </Typography>
                </Box>
            )}
        </Box>
    );
}

export default HomePage;
