import axios from 'axios';
import {useRef, useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";
const config = require("../../config");

export const ApiClient = axios.create({baseURL: config.api_host});
export const GeoClient = axios.create({baseURL: config.geo_host});

export const AxiosInterceptor = ({ children }: any) => {
    const [isSet, setIsSet] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {

        const reqInterceptor = (request: any) => {
            return request;
        };

        const resInterceptor = (response: any) => {
            return response;
        };

        const errInterceptor = (error: any) => {
            if (error.response) {
                error.status = error.response.status;
                if (error.response.data) {
                    error.message = error.response.data.error_description;
                }
            }
            if (error.status) {
                console.error(error.config.url + " " + error.status + " " + error.message);
            } else {
                console.error(error.config.url + " failed to send " + error.message);
            }
            /*
            if (!error.status) {
                navigate("/error?msg=" + error.message);
            }
             */
            if (error.status == 401 || error.status == 500) {
                navigate("/error?status=" + error.status);
            }
            throw error;
        };

        const apiRequestInterceptor = ApiClient.interceptors.request.use(reqInterceptor, errInterceptor);
        const apiResponseInterceptor = ApiClient.interceptors.response.use(resInterceptor, errInterceptor);
        const geoRequestInterceptor = GeoClient.interceptors.request.use(reqInterceptor, errInterceptor);
        const geoResponseInterceptor = GeoClient.interceptors.response.use(resInterceptor, errInterceptor);

        setIsSet(true);

        return () => {
            ApiClient.interceptors.request.eject(apiRequestInterceptor);
            ApiClient.interceptors.response.eject(apiResponseInterceptor);
            GeoClient.interceptors.request.eject(geoRequestInterceptor);
            GeoClient.interceptors.response.eject(geoResponseInterceptor);
        };

    }, []);

    return isSet && children;
};