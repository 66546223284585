import { Typography, Paper, Box, Link, } from '@mui/material';
import MediaCard from "../media/MediaCard";
import Features from "../common/Features";
import {formatPhoneNumber} from "react-phone-number-input";

const PropertyCard = ({property, imgWidth, imgHeight}) => {
    return (
        <Paper sx={{height: '100%'}}>
                <Box sx={{display: 'grid', gridTemplateColumns: '3fr 1fr'}}>
                    <Box sx={{margin: '10px'}}>
                        <Link href={'/properties/' + property.uid}>
                            <Typography variant="h6">{property.name}</Typography>
                        </Link>
                        {property.summary && <Typography variant="subtitle2">{property.summary}</Typography>}
                        <Typography variant="body2">{property.bedrooms}BR/{property.bathrooms}BA - Sleeps {property.sleeps}</Typography>
                        {property.features?.size && <Features features={property.features} />}
                        {property.contact && property.contact.email  &&
                            <Typography variant="body2">
                                <a href={"mailto:"+property.contact.email}>{property.contact.name}</a>
                            </Typography>
                        }
                        {property.contact && property.contact.phone &&
                            <Typography variant="body2">
                                 <a href={"tel:" + property.contact.phone}>{formatPhoneNumber(property.contact.phone)}</a>
                            </Typography>
                        }
                        {property.priceSummary && <Typography variant="body2">{property.priceSummary}</Typography>}
                    </Box>
                    {property.media && property.media.slice(0,1).map((m) => {
                        return (<Box sx={{margin: '10px', height: '100%'}}> <MediaCard media={m} maxWidth={imgWidth} maxHeight={imgHeight} /></Box>);
                    })}
                </Box>
        </Paper>
    );
}

export default PropertyCard