import React, {useEffect,useState} from 'react';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import RegisterForm from "./RegisterForm";
import config from "../../config";
import {useNavigate, useSearchParams} from "react-router-dom";
import {ApiClient} from "./AxiosClient";
import {Box, Button, Grid, Typography} from "@mui/material";

const Registration = (() => {
    const [searchParams, setSearchParams] = useSearchParams()
    const navigate = useNavigate();
    const [state, setState] = useState({
        submitted: false,
        email: null,
        complete: false
    });
    const token = searchParams.get("token");

    useEffect(() => {
        if (token && token.length > 0) {
            setState(prevState => ({...prevState, isLoading: true}));
            ApiClient.post("/owner/register",JSON.stringify({ token: token }),{
                headers: {
                    'Content-Type': `application/json`,
                }
            })
            .then(response => {
                setState(prevState => ({...prevState, complete: true, message: "Registration successful!"}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, message: "verification failed!"}));
            });
        }
    },[]);

    const submit = ((email) => {
        setState(prevState => ({...prevState, submitted: true, email: email}));
    });

    if (token && token.length > 0) {
        if (state.complete) {
            return (
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item sx={{textAlign: 'center'}}>
                            <Typography variant={"h5"}>Welcome to Vacombo!</Typography>
                        </Grid>
                        <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                            <Typography>Your registration was successful.</Typography>
                            <Typography>Login using your email address {state.email}.</Typography>
                            <Button variant="contained" sx={{mt: '1em'}} onClick={() => navigate("/account")}>Login</Button>
                        </Grid>
                    </Grid>
                </Box>
            )
        } else {
            return (
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item sx={{textAlign: 'center'}}>
                            <Typography variant="h5">User Registration</Typography>
                        </Grid>
                        <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                            <Typography>Validating email....</Typography>
                        </Grid>
                    </Grid>
                </Box>
            )
        }
    } else if (state.submitted) {
        return (
            <Box display="flex" justifyContent="center">
                <Grid container direction={"column"} sx={{width: '350px'}}>
                    <Grid item sx={{textAlign: 'center'}}>
                        <Typography variant="h5">User Registration</Typography>
                    </Grid>
                    <Grid item sx={{textAlign: 'center', marginTop: '1em'}}>
                        <Typography>An email was sent to {state.email}, please check your email to complete your registration.</Typography>
                        <Button variant="contained" sx={{mt: '1em'}} onClick={() => navigate("/")}>Home</Button>
                    </Grid>
                </Grid>
            </Box>
        )
    } else {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                <Box display="flex" justifyContent="center">
                    <Grid container direction={"column"} sx={{width: '350px'}}>
                        <Grid item>
                            <Typography variant="h5">User Registration</Typography>
                        </Grid>
                        <Grid item>
                            <RegisterForm onSubmit={submit}/>
                        </Grid>
                    </Grid>
                </Box>
            </GoogleReCaptchaProvider>
        )
    }
});

export default Registration