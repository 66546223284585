import React, { useState, useEffect, Fragment } from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import {
    Breadcrumbs,
    Grid,
    Typography,
    Link,
    useMediaQuery,
    Menu,
    MenuItem,
    Button,
    Dialog,
    DialogContent,
    Box,
    IconButton,
    Collapse,
} from '@mui/material';
import {ApiClient} from "../common/AxiosClient";
import {useAuth} from "react-oidc-context";
import config from "../../config";
import CommunityForm from "./CommunityForm";
import CommunityDetail from "./CommunityDetail";
import {theme} from "../../index";
import ReactGA from "react-ga4";
import {KeyboardArrowDown, KeyboardArrowRight} from "@mui/icons-material";
import CommunityProperties from "./CommunityProperties";

const ManageCommunity = () => {
    const { uid} = useParams();
    const auth = useAuth();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const [ community, setCommunity] = useState({});
    const [ properties, setProperties] = useState([]);
    const [ openProperties, setOpenProperties] = useState(true);
    const [ openPendingProperties, setOpenPendingProperties] = useState(false);
    const [ openOtherProperties, setOpenOtherProperties] = useState(false);
    const [ openRejectedProperties, setOpenRejectedProperties] = useState(false);
    const [ state, setState] = useState({
        uid: uid,
        needsRefresh: true,
        isLoading: false,
        isRemoving: false,
        remove: false,
        propsLoaded: false,
        isEditing: uid === "add",
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.uid !== "add" && state.needsRefresh && !state.isLoading && !state.errorMessage && !state.isEditing) {
            const token = auth.user.access_token;
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/owner/communities/" + state.uid,{
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setCommunity(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
        if (state.remove && !state.isLoading) {
            setState(prevState => ({...prevState, isLoading: true})); // avoid removing it twice
            const token = auth.user.access_token;
            ApiClient.delete("/owner/communities/" + state.uid,{
                headers: {
                    Authorization: `Bearer ${token}`,
                }})
                .then((response) => {
                    setState(prevState => ({...prevState, isLoading: false, redirect: "/account" }));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [state]);

    useEffect(() => {
        if (community && state.uid !== 'add' && !state.isLoading && !state.errorMessage) {
            const token = auth.user.access_token;
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/owner/communities/" + state.uid + "/properties",{
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setProperties(response.data);
                    setState(prevState => ({...prevState, isLoading: false, propsLoaded: true }));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [community]);

    useEffect(() => {
        if (community.address?.latitude && community.address?.longitude && properties?.nearby) {
            properties.nearby.forEach((prop) => {
                prop.distance = Math.round(haversineMiles(community.address.latitude,community.address.longitude,prop.property.address.latitude,prop.property.address.longitude) * 100)/100;
                //console.log("distance " + prop.distance);
            })
        }
    }, [properties]);

    const haversineMiles = (lat1,lon1,lat2,lon2) => {
        var dLat = (lat2-lat1) * (Math.PI/180);
        var dLon = (lon2-lon1) * (Math.PI/180);
        var a =
            Math.sin(dLat/2) * Math.sin(dLat/2) +
            Math.cos(lat1 * (Math.PI/180)) * Math.cos(lat2 * (Math.PI/180)) *
            Math.sin(dLon/2) * Math.sin(dLon/2)
        ;
        return 3960 * 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    }

    const handleEditCommunity = (popupState) => {
        ReactGA.event({
            category: "community",
            action: "edit",
            label: uid,
        });
        setState(prevState => ({...prevState, isEditing: true}));
        popupState.close();
    }
    const handleRemoveCommunity = (popupState) => {
        setState(prevState => ({...prevState, isRemoving: true}));
        popupState.close();
    }

    const handleAction = (action, puid) => {
        console.log("handleAction " + action + " prop " + puid);
        ReactGA.event({
            category: "community",
            action: action,
            label: uid,
        });
        const token = auth.user.access_token;
        let data = {
            action: action,
            puid: puid
        };
        setState(prevState => ({...prevState, isLoading: true}));
        ApiClient.post("/owner/communities/" + state.uid + "/properties", JSON.stringify(data), {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: true}));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
    }

    if (state.redirect) {
        return <Navigate to={state.redirect} />;
    }

    const breadcrumbs = (
        <Grid container direction="row" justifyContent="space-between" textAlign="center">
            <Grid item>
                <Breadcrumbs color={'primary'}>
                    <Link underline="hover" color="inherit" sx={{textDecoration: "none"}} href="/account">
                        Account
                    </Link>
                    <Typography>
                        {community.name ? community.name + " " : "New "}
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );

    if (state.isEditing) {
        return (
            <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                {breadcrumbs}
                <CommunityForm onClose={(uid) => {
                    setState(prevState => ({...prevState, uid: uid, isEditing: false, needsRefresh: true}));
                    window.history.replaceState(null, null, "/account/communities/" + uid)
                }} commData={community}/>
            </GoogleReCaptchaProvider>
        );
    } else if (state.viewProperties) {
        return (
                {breadcrumbs}
        );
    } else {
        return (
            <Fragment>
                {breadcrumbs}
                <Box>
                    {community && community.warning && <Box sx={{backgroundColor: 'warning.main', textAlign: 'center'}}>{community.warning}</Box>}
                    <Box display={"flex"} justifyContent={"right"}>
                        <PopupState variant="popover" popupId="demo-popup-menu">
                            {(popupState) => (
                                <Fragment>
                                    <Button variant="contained" {...bindTrigger(popupState)}>
                                        Actions
                                    </Button>
                                    <Menu {...bindMenu(popupState)}>
                                        <MenuItem onClick={() => {handleEditCommunity(popupState)}}>Edit</MenuItem>
                                        <MenuItem onClick={() => {handleRemoveCommunity(popupState)}}>Delete</MenuItem>
                                        <MenuItem onClick={() => {setState(prevState => ({...prevState, viewProperties: true}))}}>Nearby Properties</MenuItem>
                                    </Menu>
                                </Fragment>
                            )}
                        </PopupState>
                    </Box>
                </Box>
                <Box>
                {community &&
                    <CommunityDetail community={community} mediaPerPage={mediaPerPage}/>}
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12} md={8} alignContent={"center"}>
                <Typography variant={'h6'}>Properties</Typography>
                    <Typography variant={'h7'}>
                        <IconButton
                            onClick={() => setOpenProperties(!openProperties)}
                            aria-label="expand"
                            size="small"
                            color={'primary'}
                        >
                            {openProperties ? <KeyboardArrowDown />
                                : <KeyboardArrowRight />}
                        </IconButton>
                        Approved ({properties?.approved?.length ? properties.approved.length : 0})
                    </Typography>
                    <Collapse in={openProperties}>
                        <Box>
                                <Box sx={{pl: '1em', mb: '1em'}}>
                                    <CommunityProperties properties={properties.approved} action={handleAction}/>
                                </Box>
                        </Box>
                    </Collapse>
                    <Typography variant={'h7'}>
                        <IconButton
                            onClick={() => setOpenPendingProperties(!openPendingProperties)}
                            aria-label="expand"
                            size="small"
                            color={'primary'}
                        >
                            {openPendingProperties ? <KeyboardArrowDown />
                                : <KeyboardArrowRight />}
                        </IconButton>
                        Pending ({properties.pending?.length ? properties.pending.length: 0})
                    </Typography>
                    <Collapse in={openPendingProperties}>
                        <Box>
                            <Box sx={{pl: '1em', mb: '1em'}}>
                                <CommunityProperties properties={properties.pending} action={handleAction}/>
                            </Box>
                        </Box>
                    </Collapse>
                    <Typography variant={'h7'}>
                        <IconButton
                            onClick={() => setOpenOtherProperties(!openOtherProperties)}
                            aria-label="expand"
                            size="small"
                            color={'primary'}
                        >
                            {openOtherProperties ? <KeyboardArrowDown />
                                : <KeyboardArrowRight />}
                        </IconButton>
                        Other Nearby Properties ({properties.nearby?.length ? properties.nearby.length: 0})
                    </Typography>
                    <Collapse in={openOtherProperties}>
                        <Box>
                            <Box sx={{pl: '1em', mb: '1em'}}>
                                <CommunityProperties action={handleAction} properties={properties.nearby} />
                            </Box>
                        </Box>
                    </Collapse>
                            <Typography variant={'h7'}>
                                <IconButton
                                    onClick={() => setOpenRejectedProperties(!openRejectedProperties)}
                                    aria-label="expand"
                                    size="small"
                                    color={'primary'}
                                >
                                    {openRejectedProperties ? <KeyboardArrowDown />
                                        : <KeyboardArrowRight />}
                                </IconButton>
                                Rejected ({properties.rejected?.length ? properties.rejected.length: 0})
                            </Typography>
                            <Collapse in={openRejectedProperties}>
                                <Box>
                                    <Box sx={{pl: '1em', mb: '1em'}}>
                                        <CommunityProperties properties={properties.rejected} action={handleAction}/>
                                    </Box>
                                </Box>
                            </Collapse>
                        </Grid>
                    </Grid>
                </Box>

                <Dialog open={state.isRemoving} onClose={() => this.setState(prevState => ({...prevState, isRemoving: false}))}>
                    <DialogContent>
                        <Typography>Are you sure you want to remove {community.name}? </Typography>
                        <Button autoFocus onClick={() => {setState((prevState) => ({...prevState,isRemoving: false}))}}>Cancel</Button>
                        <Button onClick={() => {
                            ReactGA.event({
                                category: 'community',
                                action: 'remove',
                                value: uid
                            });
                            setState((prevState) => ({...prevState, remove: true, isRemoving: false}))}}>Ok</Button>
                    </DialogContent>
                </Dialog>

            </Fragment>
        );
    }
};

export default ManageCommunity;