import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import {useEffect, useState} from "react";
import {GeoClient} from "./AxiosClient";

const CompanyForm = ({onChange}) => {
    const [state, setState] = useState({
        countries: [],
        isLoading: false,
        errorMessage: null
    });

    useEffect(() => {
        if (state.isLoading) {
            return;
        }
        /*
        if (localStorage.getItem("countries")) {
            let countries = JSON.parse(localStorage.getItem("countries"));
            setState(prevData => ({...prevData, countries: countries}));
            return;
        }
         */
        setState(prevData => ({...prevData, isLoading: true}));

        GeoClient.get("/countries",{
            headers: {
                'Content-Type': `application/json`,
            }})
            .then(response => {
                localStorage.setItem("countries",JSON.stringify(response.data));
                setState(prevData => ({...prevData, isLoading: false, countries: response.data}));;
            })
            .catch(error => {
                setState(prevData => ({...prevData, isLoading: false, errorMessage: error.message}));
            })
    }, []);

    const handleChange = (event,value) => {
        if (onChange) onChange(value);
    }

    return (
        <Autocomplete
            id="country"
            openOnFocus
            options={state.countries}
            autoHighlight
            getOptionLabel={(option) => {return option.name + " (" + option.iso3 + ")"}}
            isOptionEqualToValue ={(option,value) => option.name.indexOf(value) > -1 || option.iso3.indexOf(value) > -1 || option.iso2.indexOf(value)}
            onChange={handleChange}
            renderOption={(props, option) => (
                <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://cdn.sophware.com/country-code-flag-images/icon-24px/${option.iso2.toUpperCase()}.png 2x`}
                        src={`https://cdn.sophware.com/country-code-flag-images/icon-24px/${option.iso2.toUpperCase()}.png`}
                        alt=""
                    />
                    {option.name} ({option.iso3})
                </Box>
            )}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Country"
                    autoFocus
                    margin="dense"
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
export default CompanyForm;
