import {useState, useEffect, Fragment} from 'react';
import {useNavigate } from 'react-router-dom';
import {
    Typography, Paper, Box, Link, IconButton, Grid, Breadcrumbs, Collapse,
} from '@mui/material';
import {
    Add,
    Edit,
    CheckBox,
    Help,
    KeyboardArrowUp,
    KeyboardArrowDown,
} from '@mui/icons-material';
import config from "../../config";
import {ApiClient} from "../common/AxiosClient";
import NameValue from "../common/NameValue";
import ContactForm from "../common/ContactForm";
import AccountProperties from "./AccountProperties";
import AccountCommunities from "./AccountCommunities";
import {useAuth} from "react-oidc-context";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const AccountPlan = ({plan}) => {
    if (!plan) return (<Fragment></Fragment>);

    return (
        <Fragment>
            <Paper sx={{pl: '1em', py: '1em', ml: '1em', mb: '1em'}}>
                <NameValue name={"Plan Name"} value={plan.name} />
            </Paper>
        </Fragment>
    );
};

export default AccountPlan;
