import React from 'react';
import {Dialog} from "@mui/material";
import NameValue from "./NameValue";

const WaitDialog = ({open,override}) => {
    return(
        <Dialog open={open} onClose={override}>
            <div>
                Please wait....
            </div>
        </Dialog>
    );
}

export default WaitDialog;