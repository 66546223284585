import {Box, Typography} from "@mui/material";

export default function Description({description}) {
    return (
        <Box sx={{my: '1emx'}}>
            {description && description.split('\n').map((p,i) => (
                <Typography key={i} paragraph={true} variant="body1">{p}</Typography>
            ))}
        </Box>
    );
}