import React, { useState } from 'react';
import {FormControl, InputLabel, MenuItem, FormHelperText } from "@mui/material";
import Select, { SelectChangeEvent } from '@mui/material/Select';

const PropertySelect = ({properties, init, onChange}) => {
    const [property, setProperty] = useState(null);

    const handleChange = (event: SelectChangeEvent) => {
        setProperty(event.target.value);
        onChange(event.target.value);
    };

    return (
           <FormControl fullWidth>
               <InputLabel id="prop-label">Property</InputLabel>
               <Select
                   labelId="prop-label"
                   id="prop"
                   value={init ? init : ''}
                   label="Property"
                   onChange={handleChange}
               >
                   {properties && properties.map((prop) => (
                       <MenuItem key={prop.uid} value={prop.uid}>
                           {prop.name}
                       </MenuItem>
                   ))}
               </Select>
               <FormHelperText></FormHelperText>
           </FormControl>
        );
}

export default PropertySelect;
