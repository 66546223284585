import * as React from 'react';
import {Grid, Box, Link} from '@mui/material';
import Typography from '@mui/material/Typography';
import useScript from "../hooks/useScript";

export default function EmbedHeader({title}) {
    useScript('https://cdn.sophware.com/iframe-resizer.child.js');

    return (
                <Grid container alignItems={"center"} direction={"row"}>
                    <Grid item>
                    <Box component="img" sx={{width: '30px', height:'30px', margin: '10px'}} src="/logo-black.png" />
                    </Grid>
                    <Grid item>
                    <Typography
                        variant="body1"
                        component="div"
                        sx={{ flexGrow: 1, fontWeight: "bold", marginRight: "0.4em", display: { xs: 'block', sm: 'block' } }}
                    >
                        {"Powered by"}
                    </Typography>
                    </Grid>
                    <Grid item>
                        <Link target="_" href="https://www.vacombo.com" sx={{fontWeight: "bold"}}>VACOMBO</Link>
                    </Grid>
                </Grid>
    );
}