import React, {useEffect, useState} from 'react';
import {useAuth} from "react-oidc-context";
import {useNavigate} from "react-router-dom";
import {
    TextField,
    Box,
    Button,
    IconButton,
    Checkbox,
    Grid,
    Dialog,
    DialogContent, FormControlLabel, FormGroup, Typography,
} from '@mui/material';
import {
    CheckBox,
} from '@mui/icons-material';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import WaitDialog from "./WaitDialog";
import ErrorDialog from "./ErrorDialog";
import {ApiClient} from "./AxiosClient";
import PhoneField from "./PhoneField";
import {isValidPhoneNumber} from "react-phone-number-input";

const ContactForm = ({ contact, onClose}) => {
    const auth = useAuth();
    const { executeRecaptcha } = useGoogleReCaptcha();
    const navigate= useNavigate();
    const [contactData, setContactData] = useState(contact);
    const [state, setState] = useState({
        updated: false,
        errorMessage: null,
        smsUpdated: false,
        usernameConflict: false,
        emailUpdated: false,
        readyToSubmit: true,
        isWorking: false,
    });

    useEffect(() => {
        const ready = validInput();
        setState((prevState) => ({...prevState, readyToSubmit: ready}));
    }, [contactData]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name == "sms") {
            setContactData((prevData) => ({...prevData, smsToken: null, smsCode: null }));
            setState((prevState) => ({...prevState, smsUpdated: true, smsCodeSent: false}));
        } else if (name == "email") {
            setContactData((prevData) => ({...prevData, emailToken: null, emailCode: null }));
            setState((prevState) => ({...prevState, emailUpdated: true, emailCodeSent: false}));
        } else if (name == "username") {
            setState((prevState) => ({...prevState, usernameConflict: false }));
        }
        setContactData((prevData) => ({...prevData, [name]: value }));
        setState((prevState) => ({...prevState, updated: true }));
    };

    const validInput = () => {
        if (state.updated &&
            contactData.name.trim().match( /^([a-zA-Z \-\'\.]){5,99}$/ ) &&
            (!contactData.companyName || contactData.companyName.trim().match( /^([a-zA-Z0-9 \'\-\.\@\&]){3,99}$/ )) &&
            contactData.email.trim().toLowerCase().match( /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ ) &&
            (!contactData.username || contactData.username.trim().match( /^([a-zA-Z0-9_-]){5,15}$/ )) &&
            (!contactData.phone || isValidPhoneNumber(contactData.phone,"US")) &&
            (!contactData.sms || isValidPhoneNumber(contactData.sms,"US")) &&
            (!state.smsUpdated || contactData.smsToken) &&
            (!state.emailUpdated || contactData.emailToken)
        ) {
            return true;
        }
        return false;
    }

    const verifySMS = async () => {
        if (state.isWorking) return;
        const token = await executeRecaptcha('register');
        const msg = {
            target: contactData.sms,
            captcha: token
        }
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        await ApiClient.post("/owner/verify", JSON.stringify(msg),{
            headers: {
                Authorization: `Bearer ${auth.user.access_token}`,
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setContactData(prevData=> ({...prevData, smsToken: res.data.token}));
                setState(prevState => ({...prevState, smsCodeSent: true, isWorking: false}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, errorMessage: "Error: " + error.message, isWorking: false}));
            });
    };

    const verifyEmail = () => {
    };

    const handleSave = async (e) => {
        if (state.isWorking) return;
        const token = await executeRecaptcha('register');
        contactData['captcha'] = token;
        setState(prevState => ({...prevState, errorMessage: null, isWorking: true}));
        ApiClient.put("/owner/contact", JSON.stringify(contactData),{
            headers: {
                Authorization: `Bearer ${auth.user.access_token}`,
                'Content-Type': `application/json`,
            }})
            .then(function(res) {
                setState(prevState => ({...prevState, isWorking: false}));
                onClose();
            })
            .catch(error => {
                if (error.status == 409) {
                    if (error.message === "username conflict") {
                        setState(prevState => ({...prevState, usernameConflict: true }));
                    } else if (error.message === "email conflict") {
                        setState(prevState => ({...prevState, emailConflict: true }));
                    }
                }
                setState(prevState => ({...prevState, errorMessage: "Error: " + error.message, isWorking: false}));
            });
    }

    return (
        <Box>
            <Grid container justifyContent="left" spacing={1} >
                <Grid item xs={12}>
                    <TextField
                        required
                        name="name"
                        label="Full Name"
                        value={contactData.name}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={!contactData.name || !contactData.name.trim().match( /^([a-zA-Z \-\'\.]){5,99}$/ )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        name="companyName"
                        label="Company Name"
                        value={contactData.companyName}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={contactData.companyName && !contactData.companyName.trim().match( /^([a-zA-Z0-9 \'\-\.\@\&]){3,99}$/ )}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        required
                        disabled
                        name="email"
                        label="Email"
                        type="email"
                        value={contactData.email}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={!contactData.email || !contactData.email.trim().toLowerCase().match(
                        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                        )}
                    />
                </Grid>
                {contactData.email && state.emailUpdated && state.emailCodeSent &&
                    <Grid item xs={12}>
                        <TextField
                            required
                            name={"emailCode"}
                            label="Email Pin"
                            onChange={handleInputChange}
                            error={state.emailConflict || !contactData.emailCode || !contactData.emailCode.trim().match( /^[0-9]{1,10}$/ )}
                            margin="dense"
                            helperText={"A pin was sent to your email"}
                        />
                    </Grid>
                }
                {contactData.email && state.emailUpdated && !state.emailCodeSent &&
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => {verifyEmail()}} color="primary">
                            Verify Email
                        </Button>
                    </Grid>
                }
                <Grid item xs={12}>
                    <TextField
                        name={"username"}
                        label="User Name"
                        value={contactData?.username}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={state.usernameConflict || (contactData.username && !contactData.username.trim().match( /^([a-zA-Z0-9_-]){5,10}$/ ))}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneField
                        name={"phone"}
                        label="Contact Phone"
                        value={contactData?.phone}
                        margin="dense"
                        onChange={handleInputChange}
                    />
                </Grid>
                <Grid item xs={12}>
                    <PhoneField
                        name={"sms"}
                        label="Mobile Phone"
                        value={contactData?.sms}
                        margin="dense"
                        onChange={handleInputChange}
                    />
                </Grid>
                {contactData.sms && state.smsUpdated && state.smsCodeSent &&
                    <Grid item xs={12}>
                        <Grid container display="flex-end" alignItems="center" direction={"row"}>
                            <Grid item>
                            <TextField
                                required
                                name={"smsCode"}
                                label="Mobile Phone Pin"
                                onChange={handleInputChange}
                                margin="dense"
                                error={!contactData.smsCode || !contactData.smsCode.trim().match( /^[0-9]{1,10}$/ )}
                            />
                            </Grid>
                            <Grid item>
                            <Typography sx={{ml: '1em'}}>A pin was sent to your mobile phone</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                }
                {contactData.sms && state.smsUpdated && !state.smsCodeSent &&
                    <Grid item xs={12}>
                        <Button variant="contained" onClick={() => {verifySMS()}} color="primary">
                            Verify Mobile Phone
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid item xs={12} display="flex" justifyContent={"center"}>
                {state.errorMessage && <Typography color={"warning.main"}>{state.errorMessage}</Typography>}
            </Grid>
            <Grid display={'flex'} justifyContent={'center'} container direction="row">
                <Grid item>
                    <Button disabled={!state.readyToSubmit} sx={{margin: '1em'}} variant="contained" onClick={handleSave} color="primary">
                        Submit
                    </Button>
                    <Button sx={{margin: '1em'}} variant="contained" onClick={() => onClose()} color="primary">
                        Cancel
                    </Button>
                </Grid>
            </Grid>

            <WaitDialog open={state.isWorking} override={() => setState(prevState => ({...prevState, isWorking: false}))} />
        </Box>
    );
};

export default ContactForm;