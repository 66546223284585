import React, { useCallback, useState } from 'react';
import { Box, Paper, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const maxWidth = 800;
const maxHeight = 800;

function PhotoUploader({ disabled, onFileUpload }) {
    const [dragOver, setDragOver] = useState(false);

    const handleDragOver = useCallback((event) => {
        console.log("handleDragOver");
        event.preventDefault();
        setDragOver(true);
    }, []);

    const handleDragLeave = useCallback((event) => {
        console.log("handleDragLeave");
        event.preventDefault();
        setDragOver(false);
    }, []);

    const handleDrop = useCallback( (event) => {
            event.preventDefault();
            setDragOver(false);
            if (event.dataTransfer.items) {
                for (const item of event.dataTransfer.items) {
                    console.log("handleDrop " + item.type + " size " + item.getAsFile().size);
                    const img = new Image();
                    img.onload = (() => {
                        console.log("original photo " + item.type + " dimensions " + img.width + "x" + img.height + " size " + item.getAsFile().size);
                        const scale = Math.min(Math.min(maxWidth/img.width,maxHeight/img.height),1.0);
                        createImageBitmap(img,{resizeWidth: img.width*scale, resizeHeight: img.height*scale}).then(bitmap => {
                                const canvas = document.createElement('canvas');
                                canvas.width = bitmap.width;
                                canvas.height = bitmap.height;
                                const ctx = canvas.getContext('bitmaprenderer');
                                ctx.transferFromImageBitmap(bitmap);
                                canvas.toBlob(blob => {
                                    console.log("new photo " + blob.type + " dimensions " + canvas.width + "x" + canvas.height + " size " + blob.size);
                                    onFileUpload(blob)},'image/jpeg');
                                URL.revokeObjectURL(img.src);
                            });
                        });
                    img.src = URL.createObjectURL(item.getAsFile());
                }
            }
        },
        [onFileUpload]
    );

    const handleChange = useCallback(
        (event) => {
            console.log("handleChange");
            if (event.target.files && event.target.files[0]) {
                onFileUpload(event.target.files[0]);
            }
        },
        [onFileUpload]
    );

    return (
        <Paper
            disabled={disabled}
            variant="outlined"
            onDragOver={!disabled ? handleDragOver : undefined}
            onDragLeave={handleDragLeave}
            onDrop={!disabled ? handleDrop : undefined}
            style={{
                border: disabled ? '2px dashed #F00' : dragOver ? '2px dashed #000' : '2px dashed #aaa',
                padding: 20,
                textAlign: 'center',
                cursor: 'pointer',
                background: dragOver && !disabled ? '#eee' : (disabled ? '#aaa' : '#fafafa'),
            }}
        >
            <input
                accept="image/*"
                style={{ display: 'none' }}
                id="raised-button-file"
                multiple
                type={!disabled ? "file" : undefined}
                onChange={!disabled ? handleChange : undefined}
            />
            <label htmlFor="raised-button-file">
                <Box display="flex" flexDirection="column" alignItems="center">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                        <CloudUploadIcon style={{ fontSize: 60 }} />
                    </IconButton>
                    {!disabled && <Typography>Drag and drop files here or click to select files</Typography>}
                    {disabled && <Typography sx={{color: "error.main"}}>Maximum media reached per your plan.</Typography>}
                </Box>
            </label>
        </Paper>
    );
}

export default PhotoUploader;