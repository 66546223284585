import {Fragment } from 'react';
import {Link} from 'react-router-dom';
import {
    Button,
    Box,
    Paper,
    Stack,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    Tooltip,
    TableBody, Typography,
} from '@mui/material';
import {StickyNote2Outlined} from "@mui/icons-material";

const CommunityProperties = ({properties, action}) => {

    return (
        <Fragment>
            <Box>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {properties && properties.map((cp) => (
                                    <TableRow key={cp.property ? cp.property.uid : cp.community.uid} sx={{ '& > *': { borderBottom: 'unset' } }}>
                                        {cp.property &&
                                            <TableCell component="th" scope="row">
                                                <Link to={"/properties/" + cp.property.uid}>{cp.property.name}</Link>
                                            </TableCell>
                                        }
                                        {cp.community &&
                                            <TableCell component="th" scope="row">
                                                <Link to={"/communities/" + cp.community.uid}>{cp.community.name}</Link>
                                            </TableCell>
                                        }

                                        {cp.status === "approved" && cp.property && cp.property.status === "active" &&
                                            <TableCell><Typography>Approved since {cp.updated}</Typography></TableCell>
                                        }
                                        {cp.status === "approved" && cp.property && cp.property.status !== "active" &&
                                            <TableCell><Typography>Property is inactive</Typography></TableCell>
                                        }
                                        {cp.status === "invited" &&
                                            <TableCell><Stack direction={"row"} spacing={1}><Typography>Invited {new Date(cp.created).toLocaleString()}</Typography>{cp.note && <Tooltip title={cp.note}><StickyNote2Outlined /></Tooltip> }</Stack></TableCell>
                                        }
                                        {cp.status === "requested" &&
                                            <TableCell><Stack direction={"row"} spacing={1}><Typography>Requested {new Date(cp.created).toLocaleString()}</Typography>{cp.note && <Tooltip title={cp.note}><StickyNote2Outlined /></Tooltip>}</Stack></TableCell>
                                        }
                                        {cp.status === "rejected" &&
                                            <TableCell><Stack direction={"row"} spacing={1}><Typography>Rejected {new Date(cp.updated).toLocaleString()}</Typography>{cp.reason && <Tooltip title={cp.reason}><StickyNote2Outlined /></Tooltip>}</Stack></TableCell>
                                        }

                                        {cp.status === undefined &&
                                            <TableCell><Typography>{cp.distance} miles away</Typography></TableCell>
                                        }
                                        {cp.status === "approved" && cp.property &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.property.uid);}}>Remove</Button></TableCell>
                                        }
                                        {cp.status === "approved" && cp.community &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.community.uid);}}>Remove</Button></TableCell>
                                        }
                                        {cp.status === "invited" && cp.property &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.property.uid);}}>Cancel</Button></TableCell>
                                        }
                                        {cp.status === "invited" && cp.community &&
                                            <TableCell><Button variant="contained" onClick={() => {action("approve",cp.community.uid);}}>Accept</Button>&nbsp;<Button variant="outlined" onClick={() => {action("reject",cp.community.uid);}}>Reject</Button></TableCell>
                                        }
                                        {cp.status === "requested" && cp.community &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.community.uid);}}>Cancel</Button></TableCell>
                                        }
                                        {cp.status === "requested" && cp.property &&
                                            <TableCell><Button variant="contained" onClick={() => {action("approve",cp.property.uid);}}>Accept</Button>&nbsp;<Button variant="outlined" onClick={() => {action("reject",cp.property.uid);}}>Reject</Button></TableCell>
                                        }
                                        {cp.status === "rejected" && cp.property &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.property.uid);}}>Unreject</Button></TableCell>
                                        }
                                        {cp.status === "rejected" && cp.community &&
                                            <TableCell><Button variant="contained" onClick={() => {action("remove",cp.community.uid);}}>Unreject</Button></TableCell>
                                        }
                                        {cp.status === undefined && cp.property &&
                                            <TableCell><Button variant="contained" onClick={() => {action("invite",cp.property.uid);}}>Invite</Button></TableCell>
                                        }
                                        {cp.status === undefined && cp.community &&
                                            <TableCell><Button variant="contained" onClick={() => {action("request",cp.community.uid);}}>Request</Button></TableCell>
                                        }
                                    </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Fragment>
    );
};


export default CommunityProperties;