import React, {useEffect, useState, Fragment, forwardRef, createRef} from "react";
import {Box, TextField} from '@mui/material';
import 'react-phone-number-input/style.css';
import PhoneInput from "react-phone-number-input/input";
import {isValidPhoneNumber, formatPhoneNumber, parsePhoneNumber} from 'react-phone-number-input';

const ref = createRef();

const PhoneTextField = forwardRef((props,ref) => {
    const [phone, setPhone] = useState(formatPhoneNumber(props.phone));

    const handleInputChange = (e) => {
        if (isValidPhoneNumber(e.target.value,"US")) {
            e.target.value = parsePhoneNumber(e.target.value,"US").number;
            props.onSubmit(e);
            setPhone(formatPhoneNumber(e.target.value));
        } else {
            setPhone(e.target.value);
        }
    };

    return (<TextField
        inputRef={ref}
        fullWidth
        type={props.type}
        name={props.name}
        label={props.label}
        helperText={props.helperText}
        value={phone}
        variant={'outlined'}
        onChange={handleInputChange}
        margin={"dense"}
        error={!phone === false && !isValidPhoneNumber(phone,"US")}
    />);
});

const PhoneField = ({ label, name, value, onChange, helperText }) => {

    return (
        <Box sx={{my: '0px'}}>
            <PhoneInput
                ref={ref}
                name={name}
                helperText={helperText}
                textField
                label={label}
                defaultCountry="US"
                phone={value}
                onSubmit={onChange}
                onChange={() => {}}
                inputComponent={PhoneTextField}
            />
        </Box>
    );
};

export default PhoneField;