import PropertyCard from "../property/PropertyCard";
import {Box, useMediaQuery} from "@mui/material";
import {theme} from "../../index";

const PropertyList = ({properties,imgWidth,imgHeight}) => {
    const small = useMediaQuery(theme.breakpoints.down('md'));

   return (
        <Box sx={{margin: '20px', display: 'grid', ...(small && {gridTemplateColumns: 'repeat(1,1fr)'}), ...(!small && {gridTemplateColumns: 'repeat(2,1fr)'})}}>
            {properties && properties.map((property) => (
                <Box item sx={{margin: '5px'}} key={property.uid} xs={12} md={6} lg={4}>
                    <PropertyCard property={property} imgWidth={imgWidth} imgHeight={imgHeight} />
                </Box>
            ))}
        </Box>
        );
}

export default PropertyList;
