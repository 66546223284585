import React, {useEffect, useState} from 'react';
import {
    TextField,
    Box,
    Typography,
    Icon,
    Grid, FormControl, OutlinedInput, InputAdornment, IconButton, InputLabel,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordForm = ({ onChange }) => {
    const [ state, setState ] = useState({password: "", password2: "" });
    const [ errors, setErrors ] = useState([]);
    const [passwordValid, setPasswordValid] = React.useState(false);
    const [showPassword, setShowPassword] = React.useState(false);

    useEffect(() => {
        let err = [];
        if (state.password.length < 6 || state.password.length > 25) {
            err.push("6-25 characters");
        }
        if (state.password.toUpperCase() == state.password ||
            state.password.toLowerCase() == state.password) {
            err.push("mix of upper and lower case");
        }
        if (state.password.length < 1 || state.password.match(/^[a-zA-Z]+$/)) {
            err.push("at least one numeric or special character");
        }
        if (err.length == 0) {
            setPasswordValid(true);
        } else {
            setPasswordValid(false);
        }
        if (err.length == 0 && state.password.length > 0 && state.password2 !== state.password) {
            err.push("re-enter password does not match");
        }
        setErrors(err);
        if (err.length == 0) {
            state.event.target.name = "password";
            state.event.target.value = state.password;
            onChange(state.event);
        }
        /*
        else if (state.event) {
            state.event.target.name = "password";
            state.event.target.value = '';
            onChange(state.event);
        }
         */
    }, [state]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState((prevState )=> ({...prevState, event: e, [name]: value}));
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Grid container justifyContent="center" spacing={1} >
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="outlined-adornment-password">Password *</InputLabel>
                    <OutlinedInput
                        id="password"
                        name="password"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(prevValue => !prevValue)}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Password *"
                        value={state.password ? state.password : ''}
                        onChange={handleInputChange}
                        margin="dense"
                        error={!passwordValid}
                        inputProps={{
                            autoComplete: 'new-password'
                        }}
                    />
                </FormControl>
            </Grid>
            <Grid item xs={12}>
                <FormControl variant="outlined" fullWidth={true}>
                    <InputLabel htmlFor="outlined-adornment-password">Re-enter Password *</InputLabel>
                    <OutlinedInput
                        id="password2"
                        name="password2"
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={() => setShowPassword(prevValue => !prevValue)}
                                    onMouseDown={handleMouseDownPassword}
                                    onMouseUp={handleMouseUpPassword}
                                    edge="end"
                                >
                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                        label="Re-enter Password *"
                        value={state.password2 ? state.password2 : ''}
                        onChange={handleInputChange}
                        fullWidth
                        margin="dense"
                        error={passwordValid && state.password != state.password2}
                    />
                </FormControl>
                <Box display={state.password.length > 0 && errors.length > 0 ? 'block' : 'none'}>
                    <Typography variant={'subtitle2'} sx={{color: 'red'}}>Password Requirements:</Typography>
                    {errors && errors.map((err,n) => {
                            return (<Box key={n} sx={{paddingLeft: '5px', color: 'red'}}><Typography variant={'caption'}>{err}</Typography></Box>);
                        }
                    )}
                </Box>
            </Grid>
        </Grid>
    );
};

export default PasswordForm;