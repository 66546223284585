import { useState, Fragment } from "react";
import { useParams } from "react-router-dom";
import PropertyList from "../property/PropertyList";
import EmbedHeader from "../common/EmbedHeader";
import {ApiClient} from "../common/AxiosClient";
import WaitDialog from "../common/WaitDialog";
import ErrorDialog from "../common/ErrorDialog";
import {useEffect} from "react";
import {Grid} from "@mui/material";
import CommunityGuidelines from "./CommunityGuidelines";

export default function EmbededCommunity() {
    const [ community, setCommunity] = useState(null);
    const [ properties, setProperties ] = useState(null);
    const [ errorMessage, setErrorMessage ] = useState(null);
    const { uid } = useParams();

    useEffect(() => {
        ApiClient.get("/communities/" + uid, {
            headers: {
                'Content-Type': `application/json`,
            }})
            .then(response => {
                setCommunity(response.data);
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
    }, []);

    useEffect(() => {
        ApiClient.get("/communities/" + uid + "/properties", {
            headers: {
                'Content-Type': `application/json`,
            }})
            .then(response => {
                setProperties(response.data);
            })
            .catch(error => {
                setErrorMessage(error.message);
            })
    }, [community]);

    return (
        <Fragment>
            <Grid container direction={"column"}>
                <Grid item>
                    <PropertyList properties={properties} />
                </Grid>
                <Grid item>
                    <CommunityGuidelines community={community} />
                </Grid>
            </Grid>
            <WaitDialog open={!properties} />
            <ErrorDialog message={errorMessage} />
        </Fragment>
    );
}