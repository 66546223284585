import {Box, Typography} from '@mui/material';

const MediaCard = ({media}) => {

    return (
        <Box justifyContent={"center"}>
            <Box component={'img'}
                sx={{width: '180px'}}
                src={media.url}
                alt={media.title}
                border={1}
                loading="lazy" />
            <Box textAlign={"center"}>
                {media.title && <Box textAlign={"center"} sx={{...(media.status === 'pending' && {backgroundColor: 'warning.main'}),...(media.status === 'rejected' && {backgroundColor: 'error.main'})}}><Typography variant={"caption"}>{media.title}</Typography></Box>}
            </Box>
        </Box>
    )
}

export default MediaCard;