import * as React from 'react';
import {useEffect} from "react";
import {useAuth } from "react-oidc-context";
import ReactRouter from './Routes';
import ReactGA from "react-ga4";
import config from "./config";

const App = () => {
    const auth = useAuth();
    useEffect(() => {
          // the `return` is important - addAccessTokenExpiring() returns a cleanup function
          return auth.events.addAccessTokenExpiring(() => {
              console.log("auth refreshing token")
          })
    }, [auth.events, auth.signinSilent])

    /*
          // automatically sign-in
    React.useEffect(() => {
          if (!hasAuthParams() &&
              !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
              auth.signinRedirect();
          }
    }, [auth.isAuthenticated, auth.activeNavigator, auth.isLoading, auth.signinRedirect]);

    switch (auth.activeNavigator) {
        case "signinSilent":
          return <div>Signing you in...</div>;
        case "signoutRedirect":
          return <div>Signing you out...</div>;
        default:
            ;
    }
     */

    if (auth.isLoading) {
        return <div>Loading...</div>;
    }

    if (auth.error) {
        console.log("auth error " + auth.error.message);
    }

    // setup Google Analytics

    let ga = {
        gaOptions: {
            debug_mode: (config.env !== 'production'),
        }
    };
    if (auth.isAuthenticated) {
        ga.gaOptions.user_id = auth.user.profile.sub;
    }
    ReactGA.initialize("G-RRST30RXBT",ga);

    return (<ReactRouter />);
}

export default App;