import React, {useState, useEffect, Fragment} from 'react';
import {useNavigate } from 'react-router-dom';
import {
    Typography, Paper, Box, Link, IconButton, Grid, Breadcrumbs, Collapse,
} from '@mui/material';
import {
    Add,
    Edit,
    CheckBox,
    Help,
    KeyboardArrowDown,
    KeyboardArrowRight,
} from '@mui/icons-material';
import config from "../../config";
import {ApiClient} from "../common/AxiosClient";
import WaitDialog from "../common/WaitDialog";
import ContactForm from "../common/ContactForm";
import AccountProperties from "./AccountProperties";
import AccountCommunities from "./AccountCommunities";
import {useAuth} from "react-oidc-context";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";
import AccountContact from "./AccountContact";
import AccountPlan from "./AccountPlan";

const Account = () => {
    const auth = useAuth();
    const navigate = useNavigate();
    const [ account , setAccount ] = useState();
    const [ openProperties, setOpenProperties] = useState(true);
    const [ openCommunities, setOpenCommunities] = useState(false);
    const [ openOwner, setOpenOwner] = useState(false);
    const [ openPlan, setOpenPlan] = useState(false);
    const [ state, setState] = useState({
        isLoading: false,
        editOwner: false,
        needsRefresh: true,
        errorMessage: null
    });

    useEffect(() => {
        if (!state.isLoading && !state.errorMessage && state.needsRefresh) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.put(config.api_host + "/owner",
                JSON.stringify(auth.user.profile), {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response => {
                    setState(prevState=> ({...prevState, isLoading: false, needsRefresh: false}));
                    response.data.user = auth.user.profile;
                    sessionStorage.setItem("account",JSON.stringify(response.data));
                    if (response.data.communities?.length > 0) {
                       setOpenCommunities(true);
                    }
                    setAccount(response.data);
                })
                .catch(error => {
                    setState(prevState=> ({...prevState, errorMessage: error.message }));
                });
        }
    }, [state]);

    if (!auth.isAuthenticated){
        auth.signinRedirect();
        return;
    }

    if (state.editOwner) {
        return (
            <Fragment>
                <GoogleReCaptchaProvider reCaptchaKey={config.recaptcha_site_key}>
                    <Box display="flex" justifyContent="center">
                        <Grid container direction={"column"} sx={{width: '350px'}}>
                            <Grid item sx={{textAlign: 'center'}}>
                                <Typography variant="h5">Account Owner</Typography>
                            </Grid>
                            <Grid item>
                                <ContactForm contact={account.owner} onClose={() => {setState(prevState => ({...prevState, editOwner: false, needsRefresh: true}))}}/>
                            </Grid>
                        </Grid>
                    </Box>
                </GoogleReCaptchaProvider>
            </Fragment>
                );
    }

    if (state.errorMessage) {
        return(
            <Fragment>
                <Breadcrumbs color={'primary'}>
                    <Typography>Account</Typography>
                </Breadcrumbs>
                <Typography variant={'h6'}>
                    {state.errorMessage}
                </Typography>
            </Fragment>
        );
    }


    return (
        <Fragment>
            <Breadcrumbs color={'primary'}>
                <Typography>Account</Typography>
            </Breadcrumbs>
            <Typography variant={'h6'}>
                <IconButton
                    onClick={() => setOpenProperties(!openProperties)}
                    aria-label="expand"
                    size="small"
                    color={'primary'}
                >
                    {openProperties ? <KeyboardArrowDown />
                        : <KeyboardArrowRight />}
                </IconButton>
                Managed Properties ({account?.properties.length})
                <IconButton
                    disabled={account && account.plan.propertyNum <= account.properties.length}
                    color={'primary'}
                    aria-label="add"
                    size="small"
                    onClick={() => {navigate("/account/properties/add");}}>
                    <Add />
                </IconButton>
            </Typography>
            <Collapse in={openProperties}>
                <Box sx={{pl: '1em', mb: '1em'}}>
                    <AccountProperties account={account} />
                    {account?.properties && account.plan.propertyNum <= account.properties.length &&
                        <Typography color={'warning.main'}>Maximum properties reached for your plan.</Typography>
                    }
                </Box>
            </Collapse>
            <Typography variant={'h6'}>
                <IconButton
                    onClick={() => setOpenCommunities(!openCommunities)}
                    aria-label="expand"
                    size="small"
                    color={'primary'}
                >
                    {openCommunities ? <KeyboardArrowDown />
                        : <KeyboardArrowRight />}
                </IconButton>
                Managed Communities ({account?.communities.length})
                <IconButton disabled={account && account.plan.communityNum <= account.communities.length}
                            color={'primary'}
                            aria-label="add"
                            size="small"
                            onClick={() => {navigate("/account/communities/add");}}>
                    <Add />
                </IconButton>
            </Typography>
            <Collapse in={openCommunities}>
                <Box sx={{pl: '1em', mb: '1em'}}>
                    <AccountCommunities account={account} />
                    {account?.communities && account.plan.communityNum <= account.communities.length &&
                        <Typography color='warning.main'>Maximum communities reached for your plan.</Typography>
                    }
                </Box>
            </Collapse>
            <Typography variant={'h6'}>
                <IconButton
                    onClick={() => setOpenOwner(!openOwner)}
                    aria-label="expand"
                    size="small"
                    color={'primary'}
                >
                    {openOwner ? <KeyboardArrowDown />
                        : <KeyboardArrowRight />}
                </IconButton>
                Owner Information
                <IconButton
                    onClick={() => {setState(prevState=> ({...prevState, editOwner: true }))}}
                    aria-label="edit"
                    size="small"
                    color={'primary'}
                >
                    <Edit />
                </IconButton>
            </Typography>
            <Collapse in={openOwner}>
                <AccountContact contact={account?.owner} />
            </Collapse>
            <Typography variant={'h6'}>
                <IconButton
                    onClick={() => setOpenPlan(!openPlan)}
                    aria-label="expand"
                    size="small"
                    color={'primary'}
                >
                    {openPlan ? <KeyboardArrowDown />
                        : <KeyboardArrowRight />}
                </IconButton>
                Plan Details
                <IconButton
                    onClick={() => {setState(prevState=> ({...prevState, editPlan: true }))}}
                    aria-label="edit"
                    size="small"
                    color={'primary'}
                >
                    <Edit />
                </IconButton>
            </Typography>
            <Collapse in={openPlan}>
                <AccountPlan plan={account?.plan} />
            </Collapse>

            <WaitDialog open={!account} />

        </Fragment>
    );
};

export default Account;
