import React from 'react';
import {Dialog} from "@mui/material";

const ErrorDialog = ({message,onClose}) => {
    return(
        <Dialog open={(message)} onClose={onClose}>
            <div>
                {message}
            </div>
        </Dialog>
    );
}

export default ErrorDialog;