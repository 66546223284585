import React, { useState, useEffect } from 'react';
import {useParams} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Grid, Typography, useMediaQuery} from '@mui/material';
import CommunityDetail from './CommunityDetail';
import CommunityGuidelines from './CommunityGuidelines';
import PropertyList from '../property/PropertyList';
import {ApiClient} from "../common/AxiosClient";
import Link from "@mui/material/Link";
import {useTheme} from "@mui/material/styles";
import {useAuth} from "react-oidc-context";
const Community = () => {
    const theme = useTheme();
    const auth = useAuth();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const { uid} = useParams();
    const [ community, setCommunity] = useState({});
    const [ properties, setProperties] = useState([]);
    const [ state, setState] = useState({
        uid: uid,
        needsRefresh: true,
        join: false,
        isLoading: false,
        isEditing: uid === "add",
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.needsRefresh && !state.isLoading && !state.errorMessage) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/communities/" + state.uid,{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setCommunity(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [uid]);

    useEffect(() => {
        if (state.needsRefresh && state.uid !== 'add' && !state.isLoading && !state.errorMessage) {
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/communities/" + state.uid + "/properties",{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setProperties(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, [community]);


    const breadcrumbs = (
        <Grid container direction="row" justifyContent="space-between" textAlign="center">
            <Grid item>
                <Breadcrumbs>
                    <Link underline="hover" color="inherit" href="/">
                        Home
                    </Link>
                    <Typography>
                        {community.name ? community.name + " " : "New "}
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );

    return (
        <Box>
            {breadcrumbs}
                <Grid sx={{alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                    {community && <CommunityDetail community={community} mediaPerPage={mediaPerPage} uid={state.uid}/>}
                    <Grid container spacing={2} justifyContent={"center"}>
                        <Grid item xs={12} md={8} alignContent={"center"}>
                            <CommunityGuidelines community={community} />
                        </Grid>
                    </Grid>
               {properties && <PropertyList properties={properties} />}
            </Grid>
        </Box>);
};

export default Community;