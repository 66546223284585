//require('dotenv').config();
let env = 'production';
if (process.env.NODE_ENV) {
    env = process.env.NODE_ENV;
}
const development = {
    authority: "https://auth.vacombo.com/realms/rbo",
    client_id: "rbo-ui",
    api_host: "http://localhost:8080/rbo",
    geo_host: "http://localhost:8080/geo/v1",
    redirect_uri: "http://localhost:3000/account",
    post_logout_redirect_uri: "http://localhost:3000/",
    register_route: "/register",
    recaptcha_site_key: "6Lc4gMAnAAAAAOr8aYjcDkxqWNpWJ_lypVgAJD0t"
};

const production = {
    authority: "https://auth.vacombo.com/realms/rbo",
    client_id: "rbo-ui",
    api_host: "https://api.vacombo.com/rbo",
    geo_host: "https://api.vacombo.com/geo/v1",
    redirect_uri: "https://www.vacombo.com/account",
    post_logout_redirect_uri: "https://www.vacombo.com/",
    register_route: "/register",
    recaptcha_site_key: "6Lc4gMAnAAAAAOr8aYjcDkxqWNpWJ_lypVgAJD0t"
};

const config = {
    development,
    production
};

console.log("loading config for " + JSON.stringify(env));

module.exports = config[env];
