import React, {useState, useEffect } from 'react';
import { Box, TextField, Autocomplete, Dialog, DialogContent } from '@mui/material';
import {GeoClient} from "../common/AxiosClient";
import CountryForm from "../common/CountryForm";

const CitySearch = ({ onChange }) => {
    const defaultCountry = {
        name: 'United States',
        iso2: 'US',
        iso3: 'USA',
    };
    const [openCountry, setOpenCountry] = useState(false);
    const [state , setState] = useState({
        country: defaultCountry,
        cities: null,
        city: null,
        search: '',
        isLoading: false,
        errorMessage: null,
    });

    const search = () => {
        var search = state.search.replace(/\W/g, ' ').replace(/\s\s+/g, ' ').trim();
        if (search.length < 3) return;
        setState(prevState => ({...prevState, isLoading: true}));
        console.log("searching for " + search);
        GeoClient.get("/" + state.country.iso2 + "/places?name=" + search ,{
            headers: {
                'Content-Type': `application/json`,
            }})
            .then(response => {
                console.log("found " + response.data.length + " matches");
                var options = [];
                response.data.forEach((city) => {
                   var name;
                   if (city.admin1_name) {
                       name = city.name_ascii + ", " + city.admin1_name;
                   } else {
                       name = city.name_ascii;
                   }
                   if (options.indexOf(name) > -1) {
                       city.fullName = name + " [" + city.id + "]";
                   } else {
                       city.fullName = name;
                   }
                   options.push(city.fullName);
                });
                console.log(JSON.stringify(response.data));
                setState(prevState => ({...prevState, isLoading: false, cities: response.data}));;
            })
            .catch(error => {
                setState(prevData => ({...prevData, isLoading: false, errorMessage: error.message}));
            })
    }

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            search();
        }, 500)

        return () => clearTimeout(delayDebounceFn)

    }, [state.search]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log("input change " + name + "=" + value);
    };

    const countryChange = (country) => {
        console.log("country change " + country?.name);
        if (country) {
            setState(prevData => ({...prevData, country: country, search: '', city: null, cities: null}));
        } else {
            setState(prevData => ({...prevData, country: defaultCountry, search: '', city: null, cities: null}));
        }
        setOpenCountry(false);
        if (onChange) onChange(country);

    }

     return(
        <Box alignItems={'center'} sx={{display: 'flex', flexDirection: 'row', width: '100%'}}>
            {!openCountry &&
                <Box sx={{display: 'flex', flexDirection: 'row', pr: '2px'}} onClick={() => setOpenCountry(true)}>
                    <Box sx={{ml: '3px'}}>
                    <img
                        loading="lazy"
                        width="20"
                        srcSet={`https://cdn.sophware.com/country-code-flag-images/icon-24px/${state.country.iso2.toUpperCase()}.png 2x`}
                        src={`https://cdn.sophware.com/country-code-flag-images/icon-24px/${state.country.iso2.toUpperCase()}.png`}
                        title={`${state.country.name}`}
                        alt={`${state.country.name}`}
                    />
                    </Box>
                </Box>
            }
            {openCountry &&
                <Box sx={{flexGrow: 1}}>
                    <CountryForm onChange={countryChange}/>
                </Box>
            }
            {!openCountry &&
                <Box sx={{flexGrow: 1}}>
                    <Autocomplete
                        key="city"
                        id="city"
                        options={state.cities ?state.cities: []}
                        getOptionLabel={option => option?.fullName ? option.fullName : ''}
                        value={state.city ? state.city: null}
                        filterOptions={(x) => x}
                        inputValue={state.search ? state.search: ''}
                        isOptionEqualToValue={(option, value) => value && option.id === value.id}
                        onChange={(e, value) => {
                            console.log("onChange " + JSON.stringify(value));
                            const city = {};
                            if (value) {
                                city.id = value.id;
                                city.name = value.name;
                                city.stateAbbr = value.admin1_code;
                                city.countryISO2 = value.country_code;
                                city.latitude = value.latitude;
                                city.longitude = value.longitude;
                                city.population = value.population;
                                city.fullName = value.fullName;
                                setState(prevData => ({...prevData, city: city, search: value.fullName}));
                            } else {
                                setState(prevData => ({...prevData, city: null}));
                            }
                            if (onChange) onChange(city);
                        }}
                        onInputChange={(event, value, reason) => {
                            console.log("input change " + value);
                            setState(prevData => ({...prevData, search: value}));
                        }}
                        renderInput={(params) => (
                            <TextField
                                required
                                name="city"
                                {...params}
                                label={state.country.name + " - City"}
                                margin="dense"
                            />
                        )}
                    />
               </Box>
            }
        </Box>
    );
};

export default CitySearch;