import {
    Typography,
    Link, Paper, Box,
} from '@mui/material';
import MediaCard from "../media/MediaCard";
import Features from "../common/Features";
import AddressCity from "../common/AddressCity";

const CommunityCard = ({ community}) => {
    return (
        <Paper sx={{height: '100%'}}>
            <Box sx={{display: 'grid', ...(community.media && {gridTemplateColumns: '3fr 1fr'}),...(!community.media && {gridTemplateColumns: '1fr'})}}>
                <Box sx={{margin: '10px'}}>
                    <Link href={'/communities/' + community.uid}>
                        <Typography variant="h6">{community.name}</Typography>
                    </Link>
                    {community.summary && <Typography variant="subtitle2">{community.summary}</Typography>}
                    {community.address && <AddressCity address={community.address} />}
                    {community.features?.size && <Features features={community.features} />}
                </Box>
                {community.media && community.media.slice(0,1).map((m) => {
                    return (<Box key={m.uid} sx={{margin: '10px', height: 'x100%'}}> <MediaCard media={m} /></Box>);
                })}
            </Box>
        </Paper>
    );
}

export default CommunityCard