import {
    Typography,
    Paper, Grid, Link, Box,
} from '@mui/material';
import MediaCarousel from "../media/MediaCarousel";
import Description from "../common/Description";
import Features from "../common/Features";
import Address from "../common/Address";
import {formatPhoneNumber} from "react-phone-number-input";
import AddressCity from "../common/AddressCity";

const PropertyDetail = ({property, mediaPerPage}) => {

    return (
        <Box sx={{alignItems: "center", alignContent: "center", justifyContent: "center"}}>
            <Grid container justifyContent={"center"}>
                {property.url && <Link target="_blank" href={property.url} rel="noopener" variant={"body2"}>
                    <Typography sx={{margin: "10px"}} variant="h4">{property.name}</Typography>
                </Link>}
                {!property.url &&
                    <Typography sx={{margin: "10px"}} variant="h4">{property.name}</Typography>
                }
                <Grid container direction={'row'} justifyContent={"center"} sx={{verticalAlign: "center"}}>
                    <Grid item>
                        <Paper sx={{padding: "1em" }}>
                                <Typography variant="body2">{property.bedrooms} bedroom | {property.bathrooms} bath | sleeps {property.sleeps}</Typography>
                                {property.contact?.email  &&
                                    <Typography variant="body2">
                                        <a href={"mailto:"+property.contact.email}>{property.contact.name}</a>
                                    </Typography>
                                }
                                {property.contact?.phone &&
                                    <Typography variant="body2">
                                        <a href={"tel:" + property.contact.phone}>{formatPhoneNumber(property.contact.phone)}</a>
                                    </Typography>
                                }
                                {property.priceSummary && <Typography variant="body2">{property.priceSummary}</Typography>}
                        </Paper>
                    </Grid>
                    <Grid item sx={{padding: "1em" }}>
                        {property.summary && <Typography variant="h6" sx={{marginBottom: "10px"}}>{property.summary}</Typography>}
                        {property.address && <AddressCity address={property.address} />}
                        {property.features?.length && <Features features={property.features} />}
                    </Grid>
                </Grid>
                    {property.description &&
                        <Grid container direction='column' sx={{margin: '1em', alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                            <Grid item>
                                <Typography variant='h7' sx={{fontWeight: "bold"}}>Property Description</Typography>
                            </Grid>
                            <Grid item>
                                <Description description={property.description} />
                            </Grid>
                        </Grid>
                    }
                <Grid container display='flex' direction={'column'} justifyContent={"center"}>
                    {property.media && property.media.length > 0 &&
                        <Grid item>
                            <MediaCarousel media={property.media} mediaPerPage={mediaPerPage} />
                        </Grid>
                    }
                </Grid>
            </Grid>
        </Box>
    );
}

export default PropertyDetail;
