import {
    Grid,
    Typography,
     } from '@mui/material';

const NameValue = ({ name, value}) => {

    return (
        <Grid container direction="row">
            <Grid item>
                <Typography sx={{fontWeight: 'medium'}}>{name + ":"}</Typography>
            </Grid>
            <Grid item>
                <Typography sx={{pl: '0.5em'}}>{value}</Typography>
            </Grid>
        </Grid>
    );
};

export default NameValue;