import React, { useEffect, useState} from 'react';
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";
import axios from "axios";
import {
    Box,
    Typography,
    TextField,
    Button,
    Grid,
    Chip,
    Autocomplete,
    MobileStepper,
    Paper,
    useMediaQuery
} from '@mui/material';
import AddressForm from '../common/AddressForm';
import MediaForm from '../media/MediaForm';
import PropertyDetail from './PropertyDetail';
import {useAuth} from "react-oidc-context";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import {ApiClient} from "../common/AxiosClient";
import {theme} from "../../index";

const PropertyForm = ({ onClose, propData }) => {
    const { executeRecaptcha } = useGoogleReCaptcha();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const auth = useAuth();
    const [state, setState] = useState({
        media: [], missingInput: false, activeStep: 0, loading: 0, loadingMedia: false, isLoading: false, saved: false, errorMessage: null});
    const [property, setProperty] = useState(
        propData || {
            type: 'House'
        }
    );

    useEffect(() => {
        if (auth.isAuthenticated && !state.isLoading && !state.errorMessage) {
            if (!state.propFeatures) {
                setState(prevState=> ({...prevState, isLoading: true}));
                ApiClient.get("/owner/properties/features",{
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                    .then(response => {
                        setState(prevState=> ({...prevState, isLoading: false, propFeatures: response.data}));
                    })
                    .catch(error => {
                        setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                    })
            }
            if (!state.propTypes) {
                setState(prevState=> ({...prevState, isLoading: true}));
                ApiClient.get("/owner/properties/types",{
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                    .then(response => {
                        setState(prevState=> ({...prevState, isLoading: false, propTypes: response.data}));
                    })
                    .catch(error => {
                        setState(prevState=> ({...prevState, isLoading: false, errorMessage: error.message }));
                    });
            }
        }
        if (state.saved && state.loadingMedia) {
            if (state.loading === 0) {
                setState(prevState => ({...prevState, loadingMedia: false, isLoading: false}));
                onClose(property.uid);
            }
        }
    }, [state]);

    useEffect(() => {
        if (!state.saved || state.loadingMedia) return;
        setState(prevState => ({...prevState, loadingMedia: true}));
        state.media && state.media.map((m,i) => {
            if (m.status === "pending" && property.media[i].updateURL && m.url && m.blob) {
                property.media[i].url = m.url;

                property.media[i].status = "uploading";
                setState(prevState => ({...prevState, loading: ++prevState.loading}));
                axios.put(property.media[i].updateURL,
                    m.blob, {
                    headers: {
                        'Content-Type': m.contentType,
                    },
                })
                .then(res => {
                    setState(prevState => ({...prevState, loading: --prevState.loading}));
                    property.media[i].status = "uploaded";
                    URL.revokeObjectURL(m.url);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
            }
        })
    }, [property]);

    const handleSave = async () => {
        try {
            property.captcha = await executeRecaptcha('property');
        } catch (err) {
            console.error("captcha failed " + err);
        }
        setState(prevState => ({...prevState, media: property.media, isLoading: true}));
        if (property.uid) {
            ApiClient.patch("/owner/properties/" + property.uid,JSON.stringify(property),
                {
                    headers: {
                        'Authorization': `Bearer ${auth.user.access_token}`,
                        'Content-Type': `application/json`,
                    }})
                .then((response) => {
                    setProperty(response.data);
                    setState(prevState => ({...prevState, saved: true}));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
        } else {
            ApiClient.post("/owner/properties", JSON.stringify(property),
                {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }})
                .then((response) => {
                    setProperty(response.data);
                    setState(prevState => ({...prevState, saved: true}));
                })
                .catch(error => {
                    setState(prevState => ({...prevState, errorMessage: error.message }));
                });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProperty((prevData) => ({ ...prevData, [name]: value }));
        setState((prevState) => ({...prevState, missingInput: false} ));
    };

    const handleAddressChange = (value) => {
        setProperty((prevData) => ({ ...prevData, address: value }));
        setState((prevState) => ({...prevState, missingInput: false} ));
    }

    const handleMediaChange = (value) => {
        setProperty((prevData) => ({ ...prevData, media: value }));
        setState((prevState) => ({...prevState, missingInput: false} ));
    }

    const handleFeatureChange = (e, value) => {
        let newFeatures = Array();
        value.map((v) => {
            let a = state.propFeatures.filter((f) => f.name === v);
            if (a.length > 0) {
                a.map((f) => {newFeatures.push(f)})
            } else {
                newFeatures.push({name: v})
            }
        });
        if (newFeatures.length > 0) {
            setProperty((prevData) => ({ ...prevData, features: newFeatures}));
        } else {
            setProperty((prevData) => ({ ...prevData, features: null}));
        }
        setState((prevState) => ({...prevState, missingInput: false} ));
    };

    const validInput = (step) => {
        switch (step) {
            case 0:
                return (property.name && property.name.length > 3 && property.type && property.bedrooms && property.bathrooms && property.sleeps);
            case 1:
                return ((!property.url ||
                     property.url.trim().match( /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/ ))
                    && property.description && property.description.length >= 25)
            case 2:
                return (property.address && property.address.country && property.address.state && property.address.city);
        }
        return true;
    }
    const handleNext = () => {
        if (validInput(state.activeStep)) {
            setState((prevState) => ({...prevState, activeStep: prevState.activeStep + 1}));
        } else {
            setState((prevState) => ({...prevState, missingInput: true} ));
        }
    };

    const handleBack = () => {
        if (validInput(state.activeStep)) {
            setState((prevState) => ({...prevState, activeStep: prevState.activeStep - 1}));
        } else {
            setState((prevState) => ({...prevState, missingInput: true} ));
        }
    };

    const steps = [
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <Typography variant={"h5"}>Property Summary</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="name"
                            required
                            name="name"
                            label="Property Name"
                            value={property.name ? property.name : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={!property.name}
                            helperText={"e.g. My Mountain Hideaway"}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            key="type"
                            id="type"
                            options={state.propTypes ? state.propTypes : []}
                            value={property.type}
                            onChange={(e, value) => { setProperty(prevProp => ({...prevProp, type:  value} ))}}
                            renderInput={(params) => (
                                <TextField
                                    required
                                    name="type"
                                    {...params}
                                    label="Property Type"
                                    margin="dense"
                                    error={!property.type}
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="summary"
                            name="summary"
                            label="Listing Summary"
                            value={property.summary ? property.summary : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            helperText={"e.g. Lots of rooms with a vew!"}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            key="bedrooms"
                            required
                            name="bedrooms"
                            label="Bedrooms"
                            type="number"
                            value={property.bedrooms}
                            inputProps={{max: 20, min: "0"}}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={!property.bedrooms}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            key="bathrooms"
                            required
                            name="bathrooms"
                            label="Bathrooms"
                            type="number"
                            inputProps={{max: 20, min: 0, step: "0.5"}}
                            value={property.bathrooms}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={!property.bathrooms}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            key="sleeps"
                            required
                            name="sleeps"
                            label="Sleeps"
                            type="number"
                            inputProps={{max: 50, min: "0"}}
                            value={property.sleeps}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={!property.sleeps}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="price"
                            name="priceSummary"
                            label="Price Summary"
                            type="text"
                            value={property.priceSummary ? property.priceSummary : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            helperText={"e.g. $100/night $500/week"}
                        />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Property Details</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            key="description"
                            name="description"
                            label="Property Description"
                            type="text"
                            value={property.description ? property.description : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            multiline
                            error={!property.description || property.description.length < 25}
                            rows={5}
                            helperText="e.g. Mountain views everywhere! Access to golf cart, ...."
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            key="features"
                            multiple
                            id="features"
                            name="features"
                            value={property.features && property.features.map((feature) => feature.name)}
                            onChange={handleFeatureChange}
                            options={state.propFeatures ? state.propFeatures.map((feature) => feature.name) : []}
                            freeSolo
                            renderTags={(value: string[], getTagProps) =>
                                value.map((option: string, index: number) => (
                                    <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField
                                    key="features"
                                    {...params}
                                    label="Features"
                                    placeholder="Add Feature"
                                    margin="dense"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            key="url"
                            name="url"
                            label="Website URL"
                            value={property.url ? property.url : ''}
                            onChange={handleInputChange}
                            fullWidth
                            margin="dense"
                            error={property.url && !property.url.trim().match( /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/ )}
                            helperText="e.g. https://www.mycabin.com"
                        />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Address</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <AddressForm onChange={handleAddressChange} initAddress={property.address} />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                    <Typography variant={"h5"}>Media</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <MediaForm onChange={handleMediaChange} theMedia={property.media} />
                    </Grid>
                </Grid>
            )
        },
        {
            body: (
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"}>Review</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Paper sx={{padding: '10px'}}><PropertyDetail property={property} mediaPerPage={mediaPerPage} /></Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <Button onClick={() => {handleSave();}}>Save</Button>
                            <Button onClick={() => onClose(property.uid)}>Cancel</Button>
                        </Box>
                    </Grid>
                </Grid>
            )
        }
    ];

    const maxSteps = steps.length;

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ width: '100%', p: 2 }}>
                {steps[state.activeStep].body}
            </Box>
            {state.missingInput &&
            <Box color="warning.main" sx={{ width: '100%', p: 2 }}>
                <Typography aligntext={'center'}>Missing required Input</Typography>
            </Box>
            }
            <MobileStepper
                sx={{marginLeft: '40px', marginRight: '40px'}}
                variant="dots"
                steps={maxSteps}
                position="static"
                activeStep={state.activeStep}
                nextButton={
                    <Button
                        size="small"
                        onClick={handleNext}
                        disabled={state.activeStep === maxSteps-1}
                    >
                        Next
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowLeft />
                        ) : (
                            <KeyboardArrowRight />
                        )}
                    </Button>
                }
                backButton={
                    <Button size="small" onClick={handleBack} disabled={state.activeStep === 0}>
                        {theme.direction === 'rtl' ? (
                            <KeyboardArrowRight />
                        ) : (
                            <KeyboardArrowLeft />
                        )}
                        Back
                    </Button>
                }
            />
        </Box>
    );
};

export default PropertyForm;