import React, { useState, useEffect } from 'react';
import {Navigate, useParams} from 'react-router-dom';
import {Box, Breadcrumbs, Button, Grid, TextField, Typography, useMediaQuery} from '@mui/material';
import CommunityDetail from './CommunityDetail';
import PropertyList from '../property/PropertyList';
import {ApiClient} from "../common/AxiosClient";
import Link from "@mui/material/Link";
import {useTheme} from "@mui/material/styles";
import {useAuth} from "react-oidc-context";
import PropertySelect from "../property/PropertySelect";
import ReactGA from "react-ga4";
const JoinCommunity = ({puid,cuid}) => {
    const theme = useTheme();
    const auth = useAuth();
    const small = useMediaQuery(theme.breakpoints.down('sm'));
    const big= useMediaQuery(theme.breakpoints.up('md'));
    const mediaPerPage = big ? 3 : small ? 1 : 2;
    const { uid} = useParams();
    const [ community, setCommunity] = useState({});
    const [ properties, setProperties] = useState([]);
    const [ state, setState] = useState({
        uid: (cuid ? cuid : uid),
        puid: (cuid ? uid : puid),
        note: null,
        needsRefresh: true,
        isLoading: false,
        isEditing: uid === "add",
        join: false,
        redirect: null,
        errorMessage: null
    });

    useEffect(() => {
        if (state.needsRefresh && !state.isLoading && !state.errorMessage) {
            console.log("state " + JSON.stringify(state));
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/communities/" + state.uid,{
                method: 'GET',
                headers: {
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setState(prevState => ({...prevState, isLoading: false, needsRefresh: false}));
                    setCommunity(response.data);
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, []);

    useEffect(() => {
        if (!state.propsLoaded && !state.isLoading && !state.errorMessage) {
            const token = auth.user.access_token;
            setState(prevState=> ({...prevState, isLoading: true}));
            ApiClient.get("/owner/properties",{
                method: 'GET',
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': `application/json`,
                }})
                .then(response=> {
                    setProperties(response.data);
                    if (response.data.length == 1) {
                        setState(prevState => ({...prevState, isLoading: false, propsLoaded: true, puid: response.data[0].uid }));
                    } else {
                        setState(prevState => ({...prevState, isLoading: false, propsLoaded: true }));
                    }
                })
                .catch(error => {
                    setState(prevState => ({...prevState, isLoading: false, errorMessage: error.message }));
                });
        }
    }, []);

    const handleRequest = async () => {
        console.log("state " + JSON.stringify(state));
        let data = {
            action: 'request',
            cuid: state.uid,
            note: state.note
        }
        console.log("data " + JSON.stringify(data));
        ReactGA.event({
            category: "community",
            action: "request",
            label: state.puid,
        });
        setState(prevState => ({...prevState, isLoading: true}));
        ApiClient.post("/owner/properties/" + state.puid + "/communities",JSON.stringify(data),
            {
                headers: {
                    'Authorization': `Bearer ${auth.user.access_token}`,
                    'Content-Type': `application/json`,
                }})
            .then((response) => {
                setState(prevState => ({...prevState, redirect: '/account/properties/' + state.puid}));
            })
            .catch(error => {
                setState(prevState => ({...prevState, errorMessage: error.message }));
            });
    };

    if (state.redirect) {
        return <Navigate to={state.redirect} />;
    }

    const breadcrumbs = (
        <Grid container direction="row" justifyContent="space-between" textAlign="center">
            <Grid item>
                <Breadcrumbs color={'primary'}>
                    <Link underline="hover" color="inherit" sx={{textDecoration: "none"}} href="/">
                        Home
                    </Link>
                    <Link underline="hover" color="inherit" sx={{textDecoration: "none"}} href={"/communities/" + state.uid}>
                        {community.name}
                    </Link>
                    <Typography>
                        {"Join"}
                    </Typography>
                </Breadcrumbs>
            </Grid>
        </Grid>
    );

    return (
        <Box>
            {breadcrumbs}
            <Box sx={{alignItems: "center", alignContent: "center", justifyContent: "center"}}>
                {community && <CommunityDetail community={community} mediaPerPage={mediaPerPage}/>}
                <Grid container spacing={2} justifyContent={"center"}>
                    <Grid item xs={12} md={8} alignContent={"center"}>
                        <Box sx={{my: '1em'}}>
                            <Typography variant={"h7"} sx={{fontWeight: "bold"}}>Community Guidelines</Typography>
                            <Typography>{community.purpose}</Typography>
                        </Box>
                        {properties.length === 0 && <Typography sx={{color: 'error.main'}}>No properties.</Typography>}
                        {properties.length > 0 &&
                            <Box sx={{width: '350px'}}>
                                <Typography variant={"h7"} sx={{fontWeight: "bold"}}>Community Application</Typography>
                            <Box sx={{mt: '1em'}}>
                                <PropertySelect properties={properties} init={state.puid ? state.puid : ''} onChange={(puid) => {setState(prevState => ({...prevState, puid: puid}))}}/>
                            </Box>
                            <Box>
                                <TextField
                                    fullWidth
                                    key="note"
                                    name="note"
                                    onChange={(e) => {
                                        setState(prevState => ({...prevState, note: e.target.value}));
                                    }}
                                    label={"Note"}
                                    type="text"
                                    margin="dense"
                                    multiline
                                    rows={5}
                                    helperText={"e.g. Please consider my property for your community...."}
                                />
                            </Box>
                            <Box>
                                <Button variant="contained" disabled={!state.puid} onClick={handleRequest}>Submit</Button>
                            </Box>
                            </Box>
                        }
                    </Grid>
                </Grid>
            </Box>
        </Box>);
};

export default JoinCommunity;