import {useState} from 'react';
import {Box, Grid, Paper,} from '@mui/material';
import MediaCard from "./MediaCard";
import {NavigateBefore, NavigateNext} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";

const MediaCarousel = ({media, mediaPerPage}) => {
    const [currentPage, setCurrentPage] = useState(0);
    const [slideDirection, setSlideDirection] = useState("left");

    const handleNextPage = () => {
        setSlideDirection("left");
        setCurrentPage(prevPage => prevPage + 1);
    }

    const handlePrevPage = () => {
        setSlideDirection("right");
        setCurrentPage(prevPage => prevPage - 1);
    }

    return (
        <Grid container direction={"row"} alignItems={"center"} justifyContent={"center"}>
            <Grid item>
                <IconButton onClick={handlePrevPage} disabled={currentPage === 0}>
                    <NavigateBefore/>
                </IconButton>
            </Grid>
            {media && media.map((m,i) => (
                <Grid item key={'media-' + i}
                             sx={{
                                 padding: "10px",
                                 display: (i >= currentPage && i <= currentPage+mediaPerPage-1) ? "block" : "none",
                             }}>
                       <MediaCard media={m} />
                </Grid>
            ))}
            <Grid item>
                <IconButton onClick={handleNextPage}
                        disabled={currentPage >= (media.length - mediaPerPage)}>
                    <NavigateNext/>
                </IconButton>
            </Grid>
        </Grid>
    );
}

export default MediaCarousel;
