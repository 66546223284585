import React, {StrictMode, createContext} from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import { AuthProvider } from 'react-oidc-context';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import './index.css';
import '@patternfly/patternfly/patternfly.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {User} from "oidc-client-ts";
const config = require('./config');

const oidcConfig = {
    authority: config.authority,
    client_id: config.client_id,
    redirect_uri: config.redirect_uri,
    post_logout_redirect_uri: config.post_logout_redirect_uri,
    automaticSilentRenew: true,
    onSigninCallback: (_user: User | void): void => {
    window.history.replaceState(
        {},
        document.title,
        window.location.pathname
    )
    }
}


const SessionCache = createContext({});

export const theme = createTheme({
    palette: {
        primary: {
            light: '#4b9fea',
            main: '#1e88e5',
            dark: '#155fa0',
            contrastText: '#fff',
        },
        secondary: {
            light: '#d15353',
            main: '#c62828',
            dark: '#8a1c1c',
            contrastText: '#000',
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    //<StrictMode>
      <BrowserRouter>
          <AuthProvider {...oidcConfig}>
              <ThemeProvider theme={theme}>
                  <SessionCache.Provider value={SessionCache}>
                      <CssBaseline />
                      <App />
                  </SessionCache.Provider>
              </ThemeProvider>
          </AuthProvider>
      </BrowserRouter>
    //</StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// create our session id if one does not exist

if (!localStorage.getItem("client_id")) {
    localStorage.setItem("client_id",makeid(10));
}


function makeid(length) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
        result += characters.charAt(Math.random() * charactersLength);
    }
    return result;
}
