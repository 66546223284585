import { React, Fragment } from 'react';
import {Routes, Route, useLocation, useSearchParams, Navigate, Outlet} from 'react-router-dom';
import HomePage from './components/HomePage';
import About from './components/About';
import Registration from './components/common/Registration';
import Login from './components/common/Login';
import Logout from './components/common/Logout';
import Error from './components/common/Error';
import Community from './components/community/Community';
import EmbededCommunity from './components/community/EmbededCommunity';
import Account from './components/account/Account';
import AccountProperties from './components/account/AccountProperties';
import AccountCommunities from './components/account/AccountCommunities';
import ManageProperty from './components/property/ManageProperty';
import ManageCommunity from './components/community/ManageCommunity';
import JoinCommunity from './components/community/JoinCommunity';
import Property from './components/property/Property';
import {useAuth} from "react-oidc-context";
import EmbedHeader from "./components/common/EmbedHeader";
import {Container} from "@mui/material";
import {AxiosInterceptor} from "./components/common/AxiosClient";
import Header from "./components/common/Header";
import Toolbar from "@mui/material/Toolbar";


const ReactRouter = () => {
    const [searchParams, setSearchParams] = useSearchParams();

    return(
        <Fragment>
            <Routes>
                <Route element={<EmbededPages />}>
                    <Route  exact path="/embed/:uid" element={<EmbededCommunity />} />
                </Route>
                <Route element={<WebPages />}>
                    <Route  exact path="/login" element={<Login />} />
                    <Route  exact path="/logout" element={<Logout />} />
                    <Route  exact path="/error" element={<Error />} />
                    <Route  exact path="/register" element={<Registration token={searchParams.get('token')} />} />
                    <Route  exact path="/about" element={<About />} />
                    <Route element={<ProtectedRoutes />}>
                        <Route  exact path="/account" element={<Account />} />
                        <Route  exact path="/account/properties" element={<AccountProperties />} />
                        <Route  exact path="/account/properties/:uid" element={<ManageProperty />} />
                        <Route  exact path="/account/properties/:uid/request" element={<JoinCommunity cuid={searchParams.get('cuid')} />} />
                        <Route  exact path="/account/communities" element={<AccountCommunities />} />
                        <Route  exact path="/account/communities/:uid" element={<ManageCommunity />} />
                        <Route  exact path="/account/communities/:uid/invite" element={<JoinCommunity puid={searchParams.get('puid')} />} />
                        <Route  exact path="/communities/:uid/request" element={<JoinCommunity />} />
                    </Route>
                    <Route  exact path="/" element={<HomePage />} />
                    <Route  exact path="/properties/:uid" element={<Property />} />
                    <Route  exact path="/communities/:uid" element={<Community />} />
                    <Route  path="*" element={<NoMatch />} />
                </Route>
            </Routes>
        </Fragment>
    );
}

function WebPages() {
    return (
        <Fragment>
            <Header />
            <Toolbar />
            <Container maxWidth="lg" style={{ paddingTop: '20px' }}>
                <AxiosInterceptor>
                    <Outlet />
                </AxiosInterceptor>
            </Container>
        </Fragment>
    );
}

function EmbededPages() {
    return (
        <Fragment>
            <EmbedHeader />
            <Container maxWidth="lg" style={{ paddingTop: '20px' }}>
                <AxiosInterceptor>
                    <Outlet />
                </AxiosInterceptor>
            </Container>
        </Fragment>
    );
}

function ProtectedRoutes() {
    const auth = useAuth();
    const location = useLocation();

    return (auth.isAuthenticated ? <Outlet /> : <Navigate to="/login" state={{ from: location }} />)
}

function NoMatch() {
    const location = useLocation();
    return (
        <div>
            <h3>
                No match found {location.pathname}
            </h3>
        </div>
    );
}

export default ReactRouter;